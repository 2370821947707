import React, { Component, Fragment } from "react";
import { fetchGet, fetchPut } from "../../../utils/Fetch";
import { withRouter, Redirect } from "react-router-dom";

const initialState = {
  ID_ESTACION: "",
  NOMBRE_ESTACONR: "",
DIRECCION_ESTACION: "",
TELEFONO_ESTACION: ""
};

class EstacionEditar extends Component {
  state = {
    ...initialState,
  };

  clearState = () => {
    this.setState({
      ...initialState,
    });
  };


  async componentDidMount() {
    const { id } = this.props.match.params;

    const data = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/estacion/${id}`
    );
    this.setState({ ...data.data[0],NOMBRE_ESTACONR:data.data[0].NOMBRE_ESTACON });


  }
  UpdateState = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  validarForm = () => {
    const {NOMBRE_ESTACONR,
       DIRECCION_ESTACION,
      TELEFONO_ESTACION} = this.state;
    const noValido = !NOMBRE_ESTACONR ||!DIRECCION_ESTACION ||!TELEFONO_ESTACION;
    return noValido;
  };

  EditarEstacion = async (e) => {
    e.preventDefault();

    const data = await fetchPut(
      `${process.env.REACT_APP_SERVER}/api/estacion/${this.state.ID_ESTACION}`,
      this.state
    );
    this.setState({ data: data.data });
    alert(data.message);
    this.props.history.push("/estacion");
  };

  render() {
    const redireccion = this.props.Access("ModificarEstaciones") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );
    const mensaje = this.props.modificar ? (
      "Editar Estacion"
    ) : (
     "Detalle de Estacion"
    );
    return (
      <Fragment>
        {redireccion}
        <h1 className="text-center mb-5">{mensaje}</h1>

        <div className="row justify-content-center">
          <form
            className="col-md-8 col-sm-12"
            onSubmit={(e) => this.EditarEstacion(e)}
          >
            <div className="form-group">
              <label>Nombre:</label>
              <input
                type="text"
                name="NOMBRE_ESTACONR"
                className="form-control"
                placeholder="Nombre"
                onChange={this.UpdateState}
                  readOnly={!this.props.modificar}
                defaultValue={this.state.NOMBRE_ESTACONR}
              />
            </div>
            <div className="form-group">
              <label>Direccion:</label>
              <input
                type="text"
                name="DIRECCION_ESTACION"
                className="form-control"
                placeholder="Direccion"
                onChange={this.UpdateState}
                  readOnly={!this.props.modificar}
                defaultValue={this.state.DIRECCION_ESTACION}
              />
            </div>  <div className="form-group">
              <label>Telefono:</label>
              <input
                type="text"
                name="TELEFONO_ESTACION"
                className="form-control"
                placeholder="Telefono"
                  readOnly={!this.props.modificar}
                onChange={this.UpdateState}
                defaultValue={this.state.TELEFONO_ESTACION}
              />
            </div>
            {this.props.modificar &&(
            
            <button
              disabled={this.validarForm()}
              type="submit"
              className="btn btn-success float-right"
            >
              Editar Estacion
            </button>)}
          </form>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(EstacionEditar);

