import React, { Component, Fragment } from "react";
import {fetchGet, fetchPut } from "../../../utils/Fetch";
import { withRouter, Redirect } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const initialState = {
  ID_TIPO_UNIDAD:""
  ,NOMBRE_TIPO_UNIDAD:""
  ,FECHA_ASIGNACION:""
  ,ESTADO:true
};

class TipoUnidadNuevo extends Component {
  state = {
    ...initialState,
  };

  clearState = () => {
    this.setState({
      ...initialState,
    });
  };

  UpdateState = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  validarForm = () => {
    const {ID_TARJETA, ID_UNIDAD, FECHA_ASIGNACION,ID_PILOTO} = this.state;
    const noValido = !ID_TARJETA || !ID_UNIDAD || !FECHA_ASIGNACION || !ID_PILOTO;
    return noValido;
  };
  updateStateSelectPiloto = (Piloto) => {
    this.setState({Piloto,
      ID_PILOTO:Piloto.ID_PILOTO
    });
  };
  async componentDidMount() {

    const { id } = this.props.match.params;
  
    const dataa = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/asignaciontarjetaunidad/${id}`
    );
    this.setState({ ...dataa.data[0] });


    const data = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/tarjeta/asingacion/${false}`
    );
  
    const datapiloto = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/piloto`
    );

    const datauni = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/unidad/asingacion/${false}`
    );


    const Pilotos=datapiloto.data;
      const Piloto=Pilotos.find(pil=>pil.ID_PILOTO===dataa.data[0].ID_PILOTO)

    const Tarjetas=data.data;
    const Tarjeta=Tarjetas.find(tar=>tar.ID_TARJETA===dataa.data[0].ID_TARJETA)

    const Unidades=datauni.data;
    const Unidad=Unidades.find(tar=>tar.ID_UNIDAD===dataa.data[0].ID_UNIDAD)

    this.setState({ Tarjetas,Tarjeta,Unidades,Unidad,Pilotos,Piloto});
  }
  updateStateSelectTarjeta = (Tarjeta) => {
    this.setState({Tarjeta,
      ID_TARJETA:Tarjeta.ID_TARJETA,
    });
  };
  updateStateSelectUnidad = (Unidad) => {
    this.setState({Unidad,
      ID_UNIDAD:Unidad.ID_UNIDAD
    });
  };

  CrearTipoUnidad = async (e) => {
    e.preventDefault();

    const data = await fetchPut(
      `${process.env.REACT_APP_SERVER}/api/asignaciontarjetaunidad/${this.state.ID_ASIGNACION_TARJETA_UNIDAD}`,
      this.state
    );
    this.setState({ data: data.data });
    alert(data.message);
    this.props.history.push("/asingtarjeta");
  };

  render() {
    const redireccion = this.props.Access("ModificarAsignacionTarjetaU") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );

    const mensaje = this.props.modificar ? (
      "Editar Asingacion"
    ) : (
      "Detalle Asingacion"
    );

    return (
      <Fragment>
        {redireccion}
    <h1 className="text-center mb-5">{mensaje}</h1>

        <div className="row justify-content-center">
          <form
            className="col-md-8 col-sm-12"
            onSubmit={(e) => this.CrearTipoUnidad(e)}
          >
            <div className="form-group">
              <label>Tarjeta:</label>
              <Select
                onChange={this.updateStateSelectTarjeta}
                // options={this.state.Tarjetas}
                isMulti={false}
                components={makeAnimated()}
                placeholder={"Seleccione la tarjeta"}
                getOptionLabel={(options) => options.NUMERO_TARJETA}
                getOptionValue={(options) => options.ID_TARJETA}
                value={this.state.Tarjeta}
              />
            </div>
            <div className="form-group">
              <label>Unidad:</label>
              <Select
                onChange={this.updateStateSelectUnidad}
                // options={this.state.Unidades}
                isMulti={false}
                isDisabled={false}
                components={makeAnimated()}
                placeholder={"Seleccione la unidad"}
                getOptionLabel={(options) => options.NOMBRE_UNIDAD}
                getOptionValue={(options) => options.ID_UNIDAD}
                value={this.state.Unidad}
              />
            </div>
            <div className="form-group">
              <label>Piloto:</label>
              <Select
                onChange={this.updateStateSelectPiloto}
                options={this.state.Pilotos}
                isMulti={false}
                isDisabled={!this.props.modificar}
                components={makeAnimated()}
                placeholder={"Seleccione el Piloto"}
                getOptionLabel={(options) => options.NOMBRE_PILOTO}
                getOptionValue={(options) => options.ID_PILOTO}
                value={this.state.Piloto}
              />
            </div>
            <div className="form-group">
              <label>FECHA_ASIGNACION:</label>
              <input
                type="date"
                name="FECHA_ASIGNACION"
                className="form-control"
                placeholder="FECHA_ASIGNACION"
                readOnly={!this.props.modificar}
                onChange={this.UpdateState}
                defaultValue={this.state.FECHA_ASIGNACION}
              />
            </div>
            {this.props.modificar && (            
              <button
                disabled={this.validarForm()}
                type="submit"
                className="btn btn-success float-right"
              >
      Editar Asignacion
    </button>
    )}
          </form>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(TipoUnidadNuevo);
