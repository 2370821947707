import React, { Component, Fragment } from "react";
import { fetchGet,fetchDelete} from "../../../utils/Fetch";
import { Link, Redirect } from "react-router-dom";
import Moment from "react-moment";

const estadoInicial = { BuscarDatos: "", data: null };

class AsignacionCombustible extends Component {
  constructor(props) {
    super(props);
    this.state = { data: estadoInicial };
  }

  Buscar = async () =>{
    const data = await fetchGet(`${process.env.REACT_APP_SERVER}/api/asignacioncombustible`);
    this.setState({ dataFiltrada: data.data, data: data.data,estado:"Re Activar"});
  }

   componentDidMount() {
   this.Buscar();
  }
  // cambioEstado = async (e) => {
  //  const data = await fetchGet(`${process.env.REACT_APP_SERVER}/api/piloto`);
  //   this.setState({ dataFiltrada: data.data, data: data.data });
  //   const { name, value } = e.target;
  //   this.setState({
  //     [name]: value,
  //   });
  // };

  BuscarDatos = (e) => {
    e.preventDefault();
    const patt = new RegExp(`${this.state.BuscarDatos}`, "gi");
    const datos = this.state.data.filter((dat) => patt.exec(dat.NUMERO_TARJETA));

    this.setState({
      dataFiltrada: datos,
    });
  };

cambioEstado = (e) => {
  const { name, value } = e.target;
  this.setState({
    [name]: value,
  });
};


Eliminar = async (ID_ASIGNACION_COMBUSTIBLE) => {
  const data = await fetchDelete(
    `${process.env.REACT_APP_SERVER}/api/AsignacionCombustible/${ID_ASIGNACION_COMBUSTIBLE}/${false}`
  );
  alert(data.message);
  const dataGet = await fetchGet(
    `${process.env.REACT_APP_SERVER}/api/AsignacionCombustible`
  );
  this.setState({ dataFiltrada: dataGet.data, data: dataGet.data });
};


ActivoReactivo =  (e) => {
  e.preventDefault();
  console.log(this.state.estado,"Re Activar")
if(this.state.estado==="Re Activar")
{
this.Inactivos();
}
else{
this.Buscar();
}
};


Reactivar = async (ID_ASIGNACION_COMBUSTIBLE) => {
  const data = await fetchDelete(
    `${process.env.REACT_APP_SERVER}/api/AsignacionCombustible/${ID_ASIGNACION_COMBUSTIBLE}/${true}`
  );
  alert(data.message);
 this.Inactivos();
};

Inactivos = async () => {
const data = await fetchGet(`${process.env.REACT_APP_SERVER}/api/AsignacionCombustible/inactivo`);
this.setState({ dataFiltrada: data.data, data: data.data,estado:"Activos"  });
};


  render() {
    const redireccion = this.props.Access("VerAsignacionCombustible") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );

    return (
      <Fragment>
        {redireccion}
        <h1 className="text-center mb-5">Asignacion Combustible</h1>
        <form class="form-inline " onSubmit={this.BuscarDatos}>
          <label className="ml-5 mr-5">
            <strong>ASIGNACION COMBUSTIBLE:</strong>
          </label>
          <input
            class="form-control mr-sm-5"
            type="search"
            name="BuscarDatos"
            onChange={this.cambioEstado}
            defaultValue={this.state.BuscarDatos}
            placeholder=""
            aria-label="Search"
          />
          <button class="btn btn-outline-dark my-2 my-sm-0" type="submit">
            Buscar
          </button>
        </form>

        {this.props.Access("CrearAsignacionCombustible") && (
          <Link
            to={`${process.env.PUBLIC_URL}/AsignacionCombustible/crear`}
            className="btn btn-link  ml-5 mr-5"
          >
            Crear
          </Link>
        )}

        {this.props.Access("ReactivarAsignacionCombustible") && (
          <button 
          onClick={this.ActivoReactivo}
          className="btn btn-link  float-right  ml-5 mr-5">
           {
             this.state.estado
           } 

          </button>
        )}

        {this.state.dataFiltrada && (
          <div className="ml-5 mr-5">
            <div className="row border">
              <div className="col-sm-2 col-xs-2">NUMERO</div>
              <div className="col-sm-1 col-xs-1">PIN</div>
              <div className="col-sm-2 col-xs-2 d-none d-sm-block">GALONES</div>
              <div className="col-sm-2 col-xs-2 d-none d-sm-block">FECHA ASIGNACION</div>
              <div className="col-sm-1 col-xs-1 d-none d-sm-block">UNIDAD</div>
              <div className="col-sm-1 col-xs-1 d-none d-sm-block">COMBUSTIBLE</div>   
              <div className="col-sm-3 col-xs-3">OPCIONES</div>
            </div>
            {this.state.dataFiltrada.map((item) => {
              const { ID_ASIGNACION_COMBUSTIBLE } = item;
              return (
                <div className="row border" key={ID_ASIGNACION_COMBUSTIBLE}>
                  <div className="col-sm-2 col-xs-2">{item.NUMERO_TARJETA}</div>
                  <div className="col-sm-1 col-xs-1 ">{item.AUTORIZACION}</div>
                  <div className="col-sm-2 col-xs-2 d-none d-sm-block">{item.GALONES_COMBUSTIBLE}</div>
                  <div className="col-sm-2 col-xs-2 d-none d-sm-block">{ new  Date((item.FECHA_ASIGNACION)).toLocaleString()}</div>
                  {/* 'es-GT', { timeZone: 'UTC' } */}
                  <div className="col-sm-1 col-xs-1 d-none d-sm-block">{item.NOMBRE_UNIDAD}</div>
                  <div className="col-sm-1 col-xs-1 d-none d-sm-block">{item.NOMBRE_COMBUSTIBLE}</div>
                

                  <div className="col-sm-3 col-xs-3">
                
                    {this.props.Access("ModificarAsignacionCombustible")  && item.ESTADO && (
                      <Link disabled
                        to={item.ACTIVA ? `${process.env.PUBLIC_URL}/AsignacionCombustible/modificar/${item.ID_ASIGNACION_COMBUSTIBLE}`: '#'}
                        className="btn btn-warning m-1"
                        disabled={!item.ACTIVA}
                        
                      >
                        Modificar
                      </Link>
                    )}

                    {this.props.Access("DetallesAsignacionCombustible")  && item.ESTADO && (
                      <Link
                        to={`${process.env.PUBLIC_URL}/AsignacionCombustible/detalle/${item.ID_ASIGNACION_COMBUSTIBLE}`}
                        className="btn btn-primary m-1"
                      >
                        Detalles
                      </Link>
                    )}

                    {this.props.Access("EliminarAsignacionCombustible")  && item.ESTADO&& (
                      <button
                        onClick={() => {
                          if (window.confirm("Seguro que deseas eliminar tipo Asignacion")) {
                            this.Eliminar(item.ID_ASIGNACION_COMBUSTIBLE);
                          }
                        }}
                        type="button"
                        className="btn btn-danger m-1 "
                        disabled={!item.ACTIVA}
                      >
                        &times; Eliminar
                      </button>
                    )}
                    <button
                        type="button"
                        className={`btn m-1 buttonTam ${item.ACTIVA===false?'btn-danger':'btn-success'}`}
                      >
                      </button>
                  {this.props.Access("ReactivarAsignacionCombustible")  && !item.ESTADO && (
                      <button
                        onClick={() => {
                          if (window.confirm("Seguro que deseas eliminar tipo de estado")) {
                            this.Reactivar(item.ID_ASIGNACION_COMBUSTIBLE);
                          }
                        }}
                        type="button"
                        className="btn btn-danger m-1 "
                      >
                        &times; Activar
                      </button>
                    )}
                   
                  </div>
                  {/* </td> */}
                  {/* </tr> */}
                </div>
              );
            })}
          </div>
        )}
      </Fragment>
    );
  }
}

export default AsignacionCombustible;
