import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchGet } from "../../utils/Fetch";


// import { fetchPost } from "../../utils/Fetch";
// import Error from '../Alertas/Error';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const initialState = {
  user: "",
  password: "",
  super:[],
diesel:[],
regular:[],
datadia:null,

};

class Inicio extends Component {
  state = {
    ...initialState,
  };

  actualizarState = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  limpiarState = () => {
    this.setState({ ...initialState });
  };

  async componentDidMount() {
    const data = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/preciocombustibledia/reporte`
    );

    if(data)
{   
  this.setState({
      super: data.data.filter((fil) => fil.NOMBRE_COMBUSTIBLE === "SUPER"),
      diesel: data.data.filter((fil) => fil.NOMBRE_COMBUSTIBLE === "DIESEL"),
      regular: data.data.filter((fil) => fil.NOMBRE_COMBUSTIBLE === "REGULAR"),
    });}

    const datadia = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/preciocombustibledia/reportedia`
    );

    if(datadia){
    this.setState({
      datadia,
    });}
  }

  validarForm = () => {
    const { user, password } = this.state;

    const noValido = !user || !password;

    return noValido;
  };

  render() {
    // const data = [
    //   {
    //     "name": "Page A",
    //     "uv": 4000,
    //     "pv": 2400,
    //     "amt": 2400
    //   },
    //   {
    //     "name": "Page B",
    //     "uv": 3000,
    //     "pv": 1398,
    //     "amt": 2210
    //   },
    //   {
    //     "name": "Page C",
    //     "uv": 2000,
    //     "pv": 9800,
    //     "amt": 2290
    //   },
    //   {
    //     "name": "Page D",
    //     "uv": 2780,
    //     "pv": 3908,
    //     "amt": 2000
    //   },
    //   {
    //     "name": "Page E",
    //     "uv": 1890,
    //     "pv": 4800,
    //     "amt": 2181
    //   },
    //   {
    //     "name": "Page F",
    //     "uv": 2390,
    //     "pv": 3800,
    //     "amt": 2500
    //   },
    //   {
    //     "name": "Page G",
    //     "uv": 3490,
    //     "pv": 4300,
    //     "amt": 2100
    //   }
    // ]

    // const redireccion = this.props.Access("VerAcceso") ? (
    //   ""
    // ) : (
    //   <Redirect to="/login" />
    // );

    return (
      <Fragment>
        <h1 className="display-5">Precios de Combustible ultimos 7 dias </h1>
        <h2 className="display-5">Super </h2>
        <div style={{ width: "100%", height: 300 }}>
          <ResponsiveContainer>
            <LineChart
              width={1200}
              height={300}
              data={this.state.super}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="FECHA_INGRESO" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="PRECIO_ANTERIOR"
                stroke="#8884d0"
              />
              <Line type="monotone" dataKey="PRECIO_ACTUAL" stroke="#82ca91" />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <h2 className="display-5">Regular </h2>
        <div style={{ width: "100%", height: 300 }}>
          <ResponsiveContainer>
            <LineChart
              width={1200}
              height={300}
              data={this.state.regular}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="FECHA_INGRESO" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="PRECIO_ANTERIOR"
                stroke="#8884d0"
              />
              <Line type="monotone" dataKey="PRECIO_ACTUAL" stroke="#82ca91" />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <h2 className="display-5">Diesel</h2>
        <div style={{ width: "100%", height: 300 }}>
          <ResponsiveContainer>
            <LineChart
              width={1200}
              height={300}
              data={this.state.diesel}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="FECHA_INGRESO" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="PRECIO_ANTERIOR"
                stroke="#8884d0"
              />
              <Line type="monotone" dataKey="PRECIO_ACTUAL" stroke="#82ca91" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(Inicio);
