import React, { Component, Fragment } from "react";
import { fetchPost,fetchGet } from "../../../utils/Fetch";
import { withRouter, Redirect } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";
 
import "react-datepicker/dist/react-datepicker.css";

const initialState = {
  ID_UNIDAD:""
  ,NOMBRE_UNIDAD:""
  ,DESCRIPCION_UNIDAD:""
  ,PLACA_UNIDAD:""
  ,VIN_UNIDAD:""
  ,ID_TIPO_UNIDAD:""
  ,ULTIMO_ODOMETRO_UNIDAD:""
  ,FECHA_ULTIMO_ODOMETRO_UNIDAD:new Date()
  ,BLOQUEADA_UNIDAD:false
  ,ID_EMPRESA:""
  ,ASIGNADA:false
  ,ESTADO:true
};

class UnidadNuevo extends Component {
  state = {
    ...initialState,
  };

  updateStateSelectTipo = (tipo) => {
    this.setState({tipo,
      ID_TIPO_UNIDAD:tipo.ID_TIPO_UNIDAD,
    });
  };

  clearState = () => {
    this.setState({
      ...initialState,
    });
  };

  UpdateState = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  validarForm = () => {
    const {NOMBRE_UNIDAD
      ,DESCRIPCION_UNIDAD
      ,PLACA_UNIDAD
      ,VIN_UNIDAD
      ,ULTIMO_ODOMETRO_UNIDAD
      ,FECHA_ULTIMO_ODOMETRO_UNIDAD
      ,ASIGNADA} = this.state;
    const noValido = !NOMBRE_UNIDAD|| ! DESCRIPCION_UNIDAD|| ! PLACA_UNIDAD|| !VIN_UNIDAD|| !ULTIMO_ODOMETRO_UNIDAD|| !FECHA_ULTIMO_ODOMETRO_UNIDAD|| ASIGNADA;
    return noValido;
  };

  CrearUnidad = async (e) => {
    e.preventDefault();

    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/unidad`,
      this.state
    );
    this.setState({ data: data.data });
    alert(data.message);
    this.props.history.push("/unidad");
  };


  async componentDidMount() {
    const data = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/tipounidad`
    );
    this.setState({ tiposunidad:data.data });
  }


  render() {
    const redireccion = this.props.Access("CrearUnidades") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );

    return (
      <Fragment>
        {redireccion}
        <h1 className="text-center mb-5">Nueva Unidad</h1>

        <div className="row justify-content-center">
          <form
            className="col-md-8 col-sm-12"
            onSubmit={(e) => this.CrearUnidad(e)}
          >
            <div className="form-group">
              <label>Nombre Unidad:</label>
              <input
                type="text"
                name="NOMBRE_UNIDAD"
                className="form-control"
                placeholder="Nombre"
                onChange={this.UpdateState}
                defaultValue={this.state.NOMBRE_UNIDAD}
              />
            </div>

            <div className="form-group">
            <label>Descripcion:</label>
            <input
              type="text"
              name="DESCRIPCION_UNIDAD"
              className="form-control"
              placeholder="Descripcion"
                onChange={this.UpdateState}
                defaultValue={this.state.DESCRIPCION_UNIDAD}
              />
            </div>

            <div className="form-group">
              <label>Placa:</label>
              <input
                type="text"
                name="PLACA_UNIDAD"
                className="form-control"
                placeholder="Placa "
                onChange={this.UpdateState}
                defaultValue={this.state.PLACA_UNIDAD}
              />
            </div>

            <div className="form-group">
              <label>Vin Unidad:</label>
              <input
                type="text"
                name="VIN_UNIDAD"
                className="form-control"
                placeholder="Vin Unidad"
                onChange={this.UpdateState}
                defaultValue={this.state.VIN_UNIDAD}
              />
            </div>

            <div className="form-group">
              <label>Ultimo odometro unidad:</label>
              <input
                type="number"
                name="ULTIMO_ODOMETRO_UNIDAD"
                className="form-control"
                placeholder="Ultimo odometro unidad"
                onChange={this.UpdateState}
                defaultValue={this.state.ULTIMO_ODOMETRO_UNIDAD}
              />
            </div>

            <div className="form-group">
              <label>Fecha Ultimo Odometro:</label>
              </div>

            <div className="form-group">

              <DatePicker selected={this.state.FECHA_ULTIMO_ODOMETRO_UNIDAD} onChange={date => this.setState({FECHA_ULTIMO_ODOMETRO_UNIDAD:date})} />
                          </div>

            <div className="form-group">
              <label>Bloqueada Unidad:</label>
              <input
                type="text"
                name="BLOQUEADA_UNIDAD"
                className="form-control"
                placeholder="Bloqueada Unidad"
                defaultValue={"NO"}
                readOnly
              />
            </div>

            <div className="form-group">
              <label>Asignada:</label>
              <input
                type="text"
                name="ASIGNADA"
                className="form-control"
                placeholder="Asignada"
                defaultValue={"NO"}
                readOnly
              />
            </div>

            <div className="form-group">
                <label>Tipo Unidad:</label>
            <Select
                onChange={this.updateStateSelectTipo}
                options={this.state.tiposunidad}
                isMulti={false}
                components={makeAnimated()}
                placeholder={"Seleccione el tipo de unidad"}
                getOptionLabel={(options) => options.NOMBRE_TIPO_UNIDAD}
                getOptionValue={(options) => options.ID_TIPO_UNIDAD}
                value={this.state.tipo}
              />
              </div>
            
            <button
              disabled={this.validarForm()}
              type="submit"
              className="btn btn-success float-right"
            >
              Nueva Unidad
            </button>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(UnidadNuevo);
