import React, { Component, Fragment } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { fetchPost } from "./utils/Fetch";

import Login from "./components/Login";
import Navbar from "./components/Navbar/navbar";
import Inicio from "./components/Inicio";

import Estadistica from "./components/Inicio/estadistica";
//Catalogos
//Pilotos
import Pilotos from "./components/Catalogos/Pilotos";
import CrearPilotos from "./components/Catalogos/Pilotos/PilotosNuevo";
import EditarPiloto from "./components/Catalogos/Pilotos/PilotosEditar";


//Tipo unidad
import TipoUnidad from "./components/Catalogos/TipoUnidad";
import TipoUnidadNuevo from "./components/Catalogos/TipoUnidad/TipoUnidadNuevo";
import TipoUnidadEditar from "./components/Catalogos/TipoUnidad/TipoUnidadEditar";
//Empresa
import Empresa from "./components/Catalogos/Empresa";
import EmpresaNuevo from "./components/Catalogos/Empresa/EmpresaNuevo";
import EmpresaEditar from "./components/Catalogos/Empresa/EmpresaEditar";
//Tarjeta
import Tarjeta from "./components/Catalogos/Tarjeta";
import TarjetaNuevo from "./components/Catalogos/Tarjeta/TarjetaNuevo";
import TarjetaEditar from "./components/Catalogos/Tarjeta/TarjetaEditar";

//accesos
import Acceso from "./components/Seguridad/Accesos";
import AccesoNuevo from "./components/Seguridad/Accesos/AccesoNuevo";
import AccesoEditar from "./components/Seguridad/Accesos/AccesoEditar";

//roles
import Rol from "./components/Seguridad/Roles";
import RolNuevo from "./components/Seguridad/Roles/RolNuevo";
import RolEditar from "./components/Seguridad/Roles/RolEditar";

//asign

import Asing from "./components/Seguridad/Asing/Asing";

//Usuarios
import Usuario from "./components/Seguridad/Usuario";
import UsuarioNuevo from "./components/Seguridad/Usuario/UsuarioNuevo";
import UsuarioEditar from "./components/Seguridad/Usuario/UsuarioEditar";

import CambioContra from "./components/Seguridad/Usuario/CambioContra";

//Combustible
import Combustible from "./components/Catalogos/Combustible";
import CombustibleNuevo from "./components/Catalogos/Combustible/CombustibleNuevo";
import CombustibleEditar from "./components/Catalogos/Combustible/CombustibleEditar";

//Estacion
import Estacion  from "./components/Catalogos/Estacion";
import EstacionNuevo from "./components/Catalogos/Estacion/EstacionNuevo";
import EstacionEditar from "./components/Catalogos/Estacion/EstacionEditar";

//Estacion
import Unidad  from "./components/Catalogos/Unidades";
import UnidadNuevo from "./components/Catalogos/Unidades/UnidadNuevo";
import UnidadEditar from "./components/Catalogos/Unidades/UnidadEditar";


//PrecioDia
import PrecioDia from "./components/Catalogos/PrecioDia/index";
// import PrecioDiaNuevo from "./components/Catalogos/PrecioDia/PrecioDiaNuevo";
import PrecioDiaEditar from "./components/Catalogos/PrecioDia/PrecioDiaEditar";

//ConsumoCombustible
import ConsumoCombustible  from "./components/Operaciones/ConsumoCombustible";
import ConsumoCombustibleNuevo from "./components/Operaciones/ConsumoCombustible/ConsumoCombustibleNuevo";
import Reversion from "./components/Operaciones/Reversiones";

//AsignacionCombustible
import AsignacionCombustible  from "./components/Operaciones/AsignacionCombustible";
import AsignacionCombustibleNuevo from "./components/Operaciones/AsignacionCombustible/AsignacionCombustibleNuevo";
import AsignacionCombustibleEditar from "./components/Operaciones/AsignacionCombustible/AsignacionCombustibleEditar";



//Asingtarjeta
import Asingtarjeta  from "./components/Operaciones/TarjetaUnidad";
import AsingtarjetaNuevo from "./components/Operaciones/TarjetaUnidad/AsingtarjetaNuevo";
import AsingtarjetaEditar from "./components/Operaciones/TarjetaUnidad/AsingtarjetaEditar";


//AsingtarjetaPivote
import AsingtarjetaPivote  from "./components/Operaciones/TarjetaUnidadPivote";
import AsingtarjetaNuevoPivote from "./components/Operaciones/TarjetaUnidadPivote/AsingtarjetaNuevo";
import AsingtarjetaEditarPivote from "./components/Operaciones/TarjetaUnidadPivote/AsingtarjetaEditar";

//AUTORIZACION

import AutorizacionNuevo from "./components/Operaciones/Autorizacion/AutorizacionNuevo";
import AutorizacionNuevoPivote from "./components/Operaciones/Autorizacion/AutorizacionNuevoPivote";


//REPORTES

import Reportes from "./components/Reportes";
import ReportesDos from './components/Reportes/Reporte2';
import ReportesTres from './components/Reportes/Reporte3';
import ReportesCuatro from './components/Reportes/Reporte4';
import ReportesPagos from './components/Reportes/ReportePagos';
import ReportePagosListo from './components/Reportes/ReportePagosListo';

import ReportePagosListoDos from './components/Reportes/ReportePagosListoDos';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = { auth: false };
  }

  async componentDidMount() {
    await this.authenticateToken();
  }

  authenticateToken = async () => {
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/authenticateToken`,
      this.state
    );


if(data)
   { 
     this.auth(data.data);}
   else{
    this.auth(false);
   }
  };

  Access = (acceso) => {
    const { auth  } = this.state;
    // console.log('auth',auth)
    let resultadoBusqueda;

    if(auth){
      auth.map((data, index) => {
        // console.log(data.NOMBRE_ACCESO,data.NOMBRE_ACCESO === acceso)
        // return data.accesses.map((acc, index_p) => {
          if (data.NOMBRE_ACCESO === acceso) {
  

            resultadoBusqueda = true;
            return true;
          } else {
            return false;
          }
        // });
      });
    }
    else{
      return false;
    }
    return !!resultadoBusqueda;
  };

  auth = (auth) => {
    this.setState({
      auth,
    });
  };

  cerrarsesion = async () => {
    await localStorage.removeItem("token", "");
    this.authenticateToken();

  };

  render() {
    const mensaje = this.state.auth ? (
      <Fragment> 
      <p>{`Bienvenido: ${this.state.auth[0].NOMBRE_USUARIO}`}</p>
      <button className="btn btn-light" onClick={this.cerrarsesion}>Cerrar Sesion</button>
</Fragment>
      // `Bienvenido: Prueba de login`
    ) : (
      <Redirect to="/" />
    );

    return (
      <Router>
    
          {/* {this.authenticateToken()} */}
          <Navbar auth={this.state.auth} authenticateToken={this.authenticateToken}   Access={this.Access}/>
          <header className=" container-fluid App-header ">
          <p className="text-right">{mensaje}</p>
          <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => <Inicio auth={this.auth}   Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/login`} render={() => <Login auth={this.auth}   Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/estadistica`} render={() => <Estadistica auth={this.auth}   Access={this.Access}/>} />
       
          
          {/* Catalogos*/}
          {/* Pilotos  */}
          <Route exact path={`${process.env.PUBLIC_URL}/pilotos`} render={() => <Pilotos Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/pilotos/crear`} render={() => <CrearPilotos Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/pilotos/detalle/:id`} render={() => <EditarPiloto modificar={false} Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/pilotos/modificar/:id`} render={() => <EditarPiloto modificar={true} Access={this.Access}/>} />

          {/* Tipo Unidad */}
          <Route exact path={`${process.env.PUBLIC_URL}/tipounidad`} render={() => <TipoUnidad Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/tipounidad/crear`} render={() => <TipoUnidadNuevo Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/tipounidad/detalle/:id`} render={() => <TipoUnidadEditar modificar={false} Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/tipounidad/modificar/:id`} render={() => <TipoUnidadEditar modificar={true} Access={this.Access}/>} />
          {/* Empresa  */}
          <Route exact path={`${process.env.PUBLIC_URL}/empresa`} render={() => <Empresa Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/empresa/crear`} render={() => <EmpresaNuevo Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/empresa/detalle/:id`} render={() => <EmpresaEditar  modificar={false} Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/empresa/modificar/:id`} render={() => <EmpresaEditar modificar={true} Access={this.Access}/>} />
          {/* Acceso */}
          <Route exact path={`${process.env.PUBLIC_URL}/acceso`} render={() => <Acceso Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/acceso/crear`} render={() => <AccesoNuevo Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/acceso/detalle/:id`} render={() => <AccesoEditar  modificar={false} Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/acceso/modificar/:id`} render={() => <AccesoEditar modificar={true} Access={this.Access}/>} />
          {/* Empresa  */}
          <Route exact path={`${process.env.PUBLIC_URL}/tarjeta`} render={() => <Tarjeta Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/tarjeta/crear`} render={() => <TarjetaNuevo Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/tarjeta/detalle/:id`} render={() => <TarjetaEditar modificar={false} Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/tarjeta/modificar/:id`} render={() => <TarjetaEditar modificar={true} Access={this.Access}/>} />
          {/*Roles */}
          <Route exact path={`${process.env.PUBLIC_URL}/roles`} render={() => <Rol Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/roles/crear`} render={() => <RolNuevo Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/roles/detalle/:id`} render={() => <RolEditar modificar={false} Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/roles/modificar/:id`} render={() => <RolEditar modificar={true} Access={this.Access}/>} />
          {/* Asing */}
          <Route exact path={`${process.env.PUBLIC_URL}/Asing/:id`} render={() => <Asing Access={this.Access}/>} />
          {/*Usuario*/}
          <Route exact path={`${process.env.PUBLIC_URL}/usuarios`} render={() => <Usuario Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/usuarios/crear`} render={() => <UsuarioNuevo Access={this.Access} auth={this.state.auth}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/usuarios/detalle/:id`} render={() => <UsuarioEditar modificar={false} Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/usuarios/modificar/:id`} render={() => <UsuarioEditar modificar={true} Access={this.Access}/>} />

          <Route exact path={`${process.env.PUBLIC_URL}/usuarios/CambioContra`} render={() => <CambioContra modificar={true} Access={this.Access} auth={this.state.auth}/>} />

          
            {/*Combustible*/}
          <Route exact path={`${process.env.PUBLIC_URL}/combustible`} render={() => <Combustible Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/combustible/crear`} render={() => <CombustibleNuevo Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/combustible/detalle/:id`} render={() => <CombustibleEditar modificar={false} Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/combustible/modificar/:id`} render={() => <CombustibleEditar modificar={true} Access={this.Access}/>} />

           {/*Estacion*/}
          <Route exact path={`${process.env.PUBLIC_URL}/estacion`} render={() => <Estacion Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/estacion/crear`} render={() => <EstacionNuevo Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/estacion/detalle/:id`} render={() => <EstacionEditar modificar={false} Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/estacion/modificar/:id`} render={() => <EstacionEditar modificar={true} Access={this.Access}/>} />

            {/*Unidad*/}
          <Route exact path={`${process.env.PUBLIC_URL}/unidad`} render={() => <Unidad Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/unidad/crear`} render={() => <UnidadNuevo Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/unidad/detalle/:id`} render={() => <UnidadEditar modificar={false} Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/unidad/modificar/:id`} render={() => <UnidadEditar modificar={true} Access={this.Access}/>} />

        {/*PrecioDia*/}
          <Route exact path={`${process.env.PUBLIC_URL}/PrecioDia`} render={() => <PrecioDia Access={this.Access}/>} />
          {/* <Route exact path={`${process.env.PUBLIC_URL}/usuarios/crear`} render={() => <UsuarioNuevo Access={this.Access}/>} /> */}
          <Route exact path={`${process.env.PUBLIC_URL}/PrecioDia/detalle/:id`} render={() => <PrecioDiaEditar modificar={false} Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/PrecioDia/modificar/:id`} render={() => <PrecioDiaEditar modificar={true} Access={this.Access}/>} />

         {/*ConsumoCombustible*/}
         <Route exact path={`${process.env.PUBLIC_URL}/ConsumoCombustible`} render={() => <ConsumoCombustible Access={this.Access} auth={this.state.auth}/>} />
         <Route exact path={`${process.env.PUBLIC_URL}/ConsumoCombustible/crear/:id`} render={() => <ConsumoCombustibleNuevo Access={this.Access} auth={this.state.auth}/>} />
         <Route exact path={`${process.env.PUBLIC_URL}/ConsumoCombustible/reversion/:id`} render={() => <Reversion Access={this.Access} auth={this.state.auth}/>} />
    
            {/*AsignacionCombustible*/}
         <Route exact path={`${process.env.PUBLIC_URL}/AsignacionCombustible`} render={() => <AsignacionCombustible Access={this.Access}/>} />
         <Route exact path={`${process.env.PUBLIC_URL}/AsignacionCombustible/crear`} render={() => <AsignacionCombustibleNuevo Access={this.Access}/>} />
         <Route exact path={`${process.env.PUBLIC_URL}/AsignacionCombustible/detalle/:id`} render={() => <AsignacionCombustibleEditar  modificar={false}  Access={this.Access}/>} />
         <Route exact path={`${process.env.PUBLIC_URL}/AsignacionCombustible/modificar/:id`} render={() => <AsignacionCombustibleEditar modificar={true}  Access={this.Access}/>} />
    

   {/*Unidad*/}
          <Route exact path={`${process.env.PUBLIC_URL}/asingtarjeta`} render={() => <Asingtarjeta Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/asingtarjeta/crear`} render={() => <AsingtarjetaNuevo Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/asingtarjeta/detalle/:id`} render={() => <AsingtarjetaEditar modificar={false} Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/asingtarjeta/modificar/:id`} render={() => <AsingtarjetaEditar modificar={true} Access={this.Access}/>} />

   {/*UnidadPivote*/}
   <Route exact path={`${process.env.PUBLIC_URL}/asingtarjetaPivote`} render={() => <AsingtarjetaPivote Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/asingtarjetaPivote/crear`} render={() => <AsingtarjetaNuevoPivote Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/asingtarjetaPivote/detalle/:id`} render={() => <AsingtarjetaEditarPivote modificar={false} Access={this.Access}/>} />
          <Route exact path={`${process.env.PUBLIC_URL}/asingtarjetaPivote/modificar/:id`} render={() => <AsingtarjetaEditarPivote modificar={true} Access={this.Access}/>} />




          
   {/*Autorizacion*/}
           <Route exact path={`${process.env.PUBLIC_URL}/autorizacion`} render={() => <AutorizacionNuevo Access={this.Access} auth={this.state.auth}/>} /> 
           <Route exact path={`${process.env.PUBLIC_URL}/autorizacionPivote`} render={() => <AutorizacionNuevoPivote Access={this.Access} auth={this.state.auth}/>} /> 
           
  {/*Reportes*/}
  <Route exact path={`${process.env.PUBLIC_URL}/Reporte_Uno`} render={() => <Reportes Access={this.Access}/>} /> 
  <Route exact path={`${process.env.PUBLIC_URL}/Reporte_Dos`} render={() => <ReportesDos Access={this.Access}/>} /> 
  <Route exact path={`${process.env.PUBLIC_URL}/Reporte_Tres`} render={() => <ReportesTres Access={this.Access}/>} /> 
  <Route exact path={`${process.env.PUBLIC_URL}/Reporte_Cuatro`} render={() => <ReportesCuatro Access={this.Access}/>} /> 
  <Route exact path={`${process.env.PUBLIC_URL}/ReportePagos`} render={() => <ReportesPagos Access={this.Access}/>} /> 

  <Route exact path={`${process.env.PUBLIC_URL}/ReportePagosListo`} render={() => <ReportePagosListo Access={this.Access}/>} /> 
  <Route exact path={`${process.env.PUBLIC_URL}/ReportePagosListoDos`} render={() => <ReportePagosListoDos Access={this.Access}/>} /> 

  
         </Switch>
        </header>
      </Router>
    );
  }
}

export default App;
