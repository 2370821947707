import React, { Component, Fragment } from "react";
import { fetchGet,fetchDelete} from "../../../utils/Fetch";
import { Link, Redirect } from "react-router-dom";

const estadoInicial = { BuscarDatos: "",BuscarDatosU: "", data: null,display:"divMostrar",
Estacion:"Palmas",
Empresa:"Smart",
displayReporte:"divNoMostrar",
displayReporteTicket:"divNoMostrar", 
dataReimprimir:{ ID_CONSUMO_COMBUSTIBLE:null
  ,ID_ASIGNACION_COMBUSTIBLE:""
  ,CANTIDAD_ASIGNADA:"",
  NOMBRE_UNIDAD:""
  ,ID_TARJETA:""
  ,ESTADO:true,
  FECHA_CONSUMO:new Date()
  ,ID_ESTACION:1,
  CANTIDAD_ACTUAL:""
  ,ID_USUARIO:1,
  PAGADO:false,
  ID_EMPRESA:1,
  MONEDA:"Q",
  PROVEEDOR:'Smartgast',
  display:"divMostrar",
  displayReporte:"divNoMostrar",
  displayReporteTicket:"divNoMostrar",
  TOTAL_CONSUMIDO:0,
  Combustible:{NOMBRE_COMBUSTIBLE:""}}
};

class ConsumoCombustible extends Component {
  constructor(props) {
    super(props);
    this.state = { ...estadoInicial };
  }

  Buscar = async () =>{
    const data = await fetchGet(`${process.env.REACT_APP_SERVER}/api/consumocombustible`);
    this.setState({ dataFiltrada: data.data, data: data.data,estado:"Re Activar"});
  }

   componentDidMount() {
    // this.setState({
    //   Estacion:this.props.auth[0].NOMBRE_ESTACON,
    //   Empresa:this.props.auth[0].NOMBRE_EMPRESA,
    // })
   this.Buscar();
  }
  // cambioEstado = async (e) => {
  //  const data = await fetchGet(`${process.env.REACT_APP_SERVER}/api/piloto`);
  //   this.setState({ dataFiltrada: data.data, data: data.data });
  //   const { name, value } = e.target;
  //   this.setState({
  //     [name]: value,
  //   });
  // };

  BuscarDatos = (e) => {
    e.preventDefault();
    const patt = new RegExp(`${this.state.BuscarDatos}`, "gi");
    const patt2 = new RegExp(`${this.state.BuscarDatosU}`, "gi");

    const datos = this.state.data.filter((dat) => patt.exec(dat.NUMERO_TARJETA));

const datos2=datos.filter((dat) => patt2.exec(dat.NOMBRE_UNIDAD));


    this.setState({
      dataFiltrada: datos2,
    });
  };

cambioEstado = (e) => {
  const { name, value } = e.target;
  this.setState({
    [name]: value,
  });
};


Eliminar = async (ID_CONSUMO_COMBUSTIBLE) => {
  const data = await fetchDelete(
    `${process.env.REACT_APP_SERVER}/api/ConsumoCombustible/${ID_CONSUMO_COMBUSTIBLE}/${false}`
  );
  alert(data.message);
  const dataGet = await fetchGet(
    `${process.env.REACT_APP_SERVER}/api/ConsumoCombustible`
  );
  this.setState({ dataFiltrada: dataGet.data, data: dataGet.data });
};


ActivoReactivo =  (e) => {
  e.preventDefault();
  console.log(this.state.estado,"Re Activar")
if(this.state.estado==="Re Activar")
{
this.Inactivos();
}
else{
this.Buscar();
}
};


Reactivar = async (ID_CONSUMO_COMBUSTIBLE) => {
  const data = await fetchDelete(
    `${process.env.REACT_APP_SERVER}/api/ConsumoCombustible/${ID_CONSUMO_COMBUSTIBLE}/${true}`
  );
  alert(data.message);
 this.Inactivos();
};

Inactivos = async () => {
const data = await fetchGet(`${process.env.REACT_APP_SERVER}/api/ConsumoCombustible/inactivo`);
this.setState({ dataFiltrada: data.data, data: data.data,estado:"Activos"  });
};


ImprimirTicket =(id)=> async () => {
  const dataReimprimir = await fetchGet(`${process.env.REACT_APP_SERVER}/api/reportes/reimprimir/${id}`);
  await this.setState({ dataReimprimir:dataReimprimir.data[0]  });

  await    this.setState({
        display:"divNoMostrar",
    displayReporteTicket:"divMostrar"
      });
  
    await window.print();
  
    await this.setState({
      display:"divMostrar",
      displayReporteTicket:"divNoMostrar"
    });
  }


  // componentDidUpdate(prevProps) {
    
  //   if (this.props.auth[0].NOMBRE_ESTACON !== prevProps.auth[0].NOMBRE_ESTACON) {
  //    this.setState({
  //     Estacion:this.props.auth[0].NOMBRE_ESTACON,
  //     Empresa:this.props.auth[0].NOMBRE_EMPRESA,
  //   })
  //   }
  // }


  render() {
    const redireccion = this.props.Access("VerConsumoCombustible") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );

    return (
      <Fragment>
         <div className={`${this.state.displayReporteTicket} comprobante`}>
               <p class="centrado comprobante">Estacion
                <br/>{this.state.dataReimprimir.NOMBRE_ESTACON}<br/>Copia</p>
                
                <p class="izquieda comprobante">
  
                Cliente
                <br/>{this.state.dataReimprimir.NOMBRE_EMPRESA}</p>
            <table>
                {/* <thead>
                    <tr>
                        <th class="cantidad">CANT</th>
                        <th class="producto">PRODUCTO</th>
                        <th class="precio">$$</th>
                    </tr>
                </thead> */}
                <tbody>
                <tr>
                        <td class="Unidad">Ticket</td>
                        <td class=""></td>
                        <td class="NOMBRE_UNIDAD">{this.state.dataReimprimir.ID_CONSUMO}</td>
                       
                    </tr> 
                    <tr>
                        <td class="Unidad">Unidad</td>
                        <td class=""></td>
                        <td class="NOMBRE_UNIDAD">{this.state.dataReimprimir.NOMBRE_UNIDAD}</td>
                       
                    </tr>
                    
                    <tr>
                        <td class="Piloto">Piloto</td>
                        <td class=""></td>
                        <td class="NOMBRE_PILOTO">{this.state.dataReimprimir.NOMBRE_PILOTO}</td>
                       
                    </tr>
                    
                    <tr>
                       <td class="Odometro">Kms/Millas</td>
                       <td class=""></td>
                        <td class="ODOTOMETRO">{this.state.dataReimprimir.ODOMETRO}</td>
                    </tr>
                    
                    <tr>
                       <td class="Combustible">Comb.</td>
                       <td class=""></td>
                        <td class="NOMBRE_COMBUSTIBLE">{this.state.dataReimprimir.COMBUSTIBLE}</td>
                    </tr>
                   
                    <tr>
                       <td class="GalDespach">Gal. Desp.</td>
                        <td class=""></td>
                        <td class="CANTIDAD_CONSUMIDA">{this.state.dataReimprimir.CANTIDAD_CONSUMIDA}</td>
                    </tr>
                    
                    <tr>
                       <td class="PrecioUnit">Precio Unit</td>
                       <td class=" centrado">Q</td>
                        <td class="PRECIO_COMBUSTIBLE">{this.state.dataReimprimir.PRECIO_COMBUSTIBLE}</td>
                    </tr>
                   
                    <tr>
                       <td class="VtaTotal">Vta Total</td>
                       <td class=" centrado">Q</td>
                        <td class="VentaTotal">{this.state.dataReimprimir.TOTAL_CONSUMIDO.toFixed(2)}</td>
                    </tr>
                    {/* <tr>
                        <td class="cantidad">1.00</td>
                        <td class="producto">COCA COLA 600 ML</td>
                        <td class="precio">$10.00</td>
                    </tr>
                    <tr>
                        <td class="cantidad"></td>
                        <td class="producto">TOTAL</td>
                        <td class="precio">$28.50</td>
                    </tr> */}
                </tbody>
            </table>
            <br/>
            <p class="centrado comprobante">____________________________</p>
            <p class="centrado comprobante">Firma</p>
            <p class="izquierda comprobante">
                <br/>Fecha y Hora de Despacho
                </p>
            <p class="izquierda comprobante">{new Date(this.state.dataReimprimir.FECHA_CONSUMO).toLocaleString()}</p>
           <p class="izquierda comprobante">
                <br/>Fecha y Hora de Impresión
                </p> 
            <p class="izquierda comprobante">{new Date().toLocaleString()}</p>
            <p class="centrado comprobante">
            <br/>Copia
                <br/><br/>
                </p>

        </div>
        <div className={this.state.display}>
        {redireccion}
        <h1 className="text-center mb-5">Despacho Combustible</h1>
        <form class="form-inline " onSubmit={this.BuscarDatos}>
          <label className="ml-5 mr-5">
            <strong>TARJETA:</strong>
          </label>
          <input
            class="form-control mr-sm-5"
            type="search"
            name="BuscarDatos"
            onChange={this.cambioEstado}
            defaultValue={this.state.BuscarDatos}
            placeholder=""
            aria-label="Search"
          />



<label className="ml-5 mr-5">
            <strong>UNIDAD:</strong>
          </label>
          <input
            class="form-control mr-sm-5"
            type="search"
            name="BuscarDatosU"
            onChange={this.cambioEstado}
            defaultValue={this.state.BuscarDatosU}
            placeholder=""
            aria-label="Search"
          />
          <button class="btn btn-outline-dark my-2 my-sm-0" type="submit">
            Buscar
          </button>
        </form>

        {this.props.Access("CrearAutorizacion") && (
          <Link
            to={`${process.env.PUBLIC_URL}/Autorizacion`}
            className="btn btn-link  ml-5 mr-5"
          >
            AUTORIZACION
          </Link>
        )}

        {/* {this.props.Access("ReactivarCombustible") && (
          <button 
          onClick={this.ActivoReactivo}
          className="btn btn-link  float-right  ml-5 mr-5">
           {
             this.state.estado
           } 

          </button>
        )}  */}

        {this.state.dataFiltrada && (
          <div className="ml-5 mr-5">
            <div className="row border">
              <div className="col-sm-2 col-xs-2">NUMERO TARJETA</div>
              <div className="col-sm-1 col-xs-1">UNIDAD</div>
              <div className="col-sm-2 col-xs-2 d-none d-sm-block">FECHA ASIGNACION</div>
              <div className="col-sm-2 col-xs-2 d-none d-sm-block">FECHA VENCE</div>
              <div className="col-sm-1 col-xs-1 d-none d-sm-block">AUTORIZACION</div>
              <div className="col-sm-1 col-xs-1 d-none d-sm-block">CANTIDAD ASIGNADA</div>   
              <div className="col-sm-2 col-xs-2 d-none d-sm-block">CANTIDAD ACTUAL</div>           
              {/* <div className="col-sm-3 col-xs-3">OPCIONES</div> */}
            </div>
            {this.state.dataFiltrada.map((item) => {
              const { ID_CONSUMO_COMBUSTIBLE } = item;
              return (
                <div className="row border" key={ID_CONSUMO_COMBUSTIBLE}>
                  <div className="col-sm-2 col-xs-2">{item.NUMERO_TARJETA}</div>
                  <div className="col-sm-1 col-xs-1">{item.NOMBRE_UNIDAD}</div>
                  <div className="col-sm-2 col-xs-2 d-none d-sm-block">{ new  Date((item.FECHA_ASIGNACION)).toLocaleString()}</div>  {/**'es-GT', { timeZone: 'UTC' } */}
                  <div className="col-sm-2 col-xs-2 d-none d-sm-block">{ new  Date((item.FECHA_VENCE)).toLocaleString()}</div>  {/**'es-GT', { timeZone: 'UTC' } */}
                  <div className="col-sm-1 col-xs-1 d-none d-sm-block">{item.AUTORIZACION}</div>
                  <div className="col-sm-1 col-xs-1 d-none d-sm-block">{item.CANTIDAD_ASIGNADA}</div>
                  <div className="col-sm-2 col-xs-2 d-none d-sm-block">{item.CANTIDAD_ACTUAL}</div>


                  {this.props.Access("RevertirCombustible")  &&  (
                      <Link
                        to={`${process.env.PUBLIC_URL}/ConsumoCombustible/reversion/${ID_CONSUMO_COMBUSTIBLE}`}
                        className="btn btn-warning m-1"
                      >
                        REVERTIR
                      </Link>
                    )}
                    {this.props.Access("Reimpresion")  &&  (
                      <button
                        onClick={this.ImprimirTicket(ID_CONSUMO_COMBUSTIBLE)}
                        className="btn btn-warning m-1"
                      >
                        RE IMPRIMIR
                      </button>
                    )}
                
                    {/* {this.props.Access("ModificarCombustible")  && item.ESTADO && (
                      <Link
                        to={`${process.env.PUBLIC_URL}/ConsumoCombustible/modificar/${item.ID_CONSUMO_COMBUSTIBLE}`}
                        className="btn btn-warning m-1"
                      >
                        Modificar
                      </Link>
                    )} 

                    {this.props.Access("DetalleCombustible")  && item.ESTADO && (
                      <Link
                        to={`${process.env.PUBLIC_URL}/ConsumoCombustible/detalle/${item.ID_CONSUMO_COMBUSTIBLE}`}
                        className="btn btn-primary m-1"
                      >
                        Detalles
                      </Link>
                    )} 

                    {this.props.Access("EliminarCombustible")  && item.ESTADO&& (
                      <button
                        onClick={() => {
                          if (window.confirm("Seguro que deseas eliminar tipo unidad")) {
                            this.Eliminar(item.ID_CONSUMO_COMBUSTIBLE);
                          }
                        }}
                        type="button"
                        className="btn btn-danger m-1 "
                      >
                        &times; Eliminar
                      </button>
                    )}
                  {this.props.Access("ReactivarCombustible")  && !item.ESTADO && (
                      <button
                        onClick={() => {
                          if (window.confirm("Seguro que deseas eliminar tipo de estado")) {
                            this.Reactivar(item.ID_CONSUMO_COMBUSTIBLE);
                          }
                        }}
                        type="button"
                        className="btn btn-danger m-1 "
                      >
                        &times; Activar
                      </button>
                    )}  */}
                
                  {/* </div> */}
                  {/* </td> */}
                  {/* </tr> */}
                </div>
              );
            })}
          </div>
        )}
        </div>
      </Fragment>
    );
  }
}

export default ConsumoCombustible;
