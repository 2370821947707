import React, { Component, Fragment } from "react";
import {  fetchPost,fetchGet } from "../../utils/Fetch";
import { Redirect } from "react-router-dom";
import { CSVLink} from "react-csv";
import Moment from "react-moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactExport from "react-export-excel";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const   headers = [
  { label: "No", key: "No" },
  { label: "UNIDAD", key: "UNIDAD" },
  { label: "FECHA CONSUMO", key: "FECHA_CONSUMO" },
  { label: "ODOMETRO", key: "ODOMETRO" },
  { label: "GALONES", key: "GALONES" },
  { label: "LITROS CONSUMIDOS", key: "LITROS_CONSUMIDOS" },
  { label: "PRECIO COMBUSTIBLE X GALON", key: "PRECIO COMBUSTIBLE X GALON" },
  { label: "MONEDA", key: "MONEDA" },
  { label: "TOTAL CONSUMIDO", key: "TOTAL_CONSUMIDO" },
  { label: "NOMBRE PILOTO", key: "NOMBRE_PILOTO" },
  { label: "PROVEEDOR", key: "PROVEEDOR" },
  { label: "COMBUSTIBLE", key: "COMBUSTIBLE" }
];


const estadoInicial = { BuscarDatos: "", data: null,estado:"Re Activar"};

class Reporte_Uno extends Component {
  constructor(props) {
    super(props);
    this.state = { data: estadoInicial,headers:headers,dataFiltrada:[], CSV:[],FECHA_INICIO: new Date(),
      FECHA_FIN:new Date(),TOTAL:{TOTAL_CONSUMIDO:0,CANTIDAD_CONSUMIDA:0} };
  }
  updateStateSelectEstacion = (Estacion) => {
    this.setState({ Estacion, ID_ESTACIONR: Estacion.ID_ESTACION });
  };
async componentDidMount() {

    const dataEstacion = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/estacion`
    );

    this.setState({
      Estaciones:dataEstacion.data
    })
  //  this.Buscar();
  }

  Buscar = async () =>{
    const data = await fetchPost(`${process.env.REACT_APP_SERVER}/api/reportes/RP_CONSUMO_COMBUSTIBLE_ESTACION`,this.state);
    if(data.data){
    await this.setState({ dataFiltrada: data.data, data: data.data});

    let TotalReal=0,ConsumoReal=0;

   
    await data.data.forEach((dat) =>{
      TotalReal+=dat.TOTAL_CONSUMIDO;
      ConsumoReal+=dat.CANTIDAD_CONSUMIDA;
    });
    
    
    this.setState({
      TOTAL:{TOTAL_CONSUMIDO:TotalReal,CANTIDAD_CONSUMIDA:ConsumoReal}
    })
    
    {/*'es-GT', { timeZone: 'UTC' } */}

    let CSV =this.state.dataFiltrada.map((rows,index)=>({No:index+1,UNIDAD:rows.NOMBRE_UNIDAD,FECHA_CONSUMO: new  Date((rows.FECHA_CONSUMO)).toLocaleString(),"ODOMETRO":rows.ODOMETRO,"GALONES":rows.CANTIDAD_CONSUMIDA,"LITROS_CONSUMIDOS":(rows.CANTIDAD_CONSUMIDA*3.785).toFixed(2),"PRECIO COMBUSTIBLE X GALON":rows.PRECIO_COMBUSTIBLE,"MONEDA":rows.MONEDA,"TOTAL_CONSUMIDO":rows.TOTAL_CONSUMIDO,"NOMBRE_PILOTO":rows.NOMBRE_PILOTO,"PROVEEDOR":rows.PROVEEDOR,"COMBUSTIBLE":rows.COMBUSTIBLE}));
    CSV=CSV.concat(
      {No:"TOTAL",UNIDAD:"",FECHA_CONSUMO:"","ODOMETRO":"","GALONES":(this.state.TOTAL.CANTIDAD_CONSUMIDA).toFixed(2),"LITROS_CONSUMIDOS":(this.state.TOTAL.CANTIDAD_CONSUMIDA*3.785).toFixed(2),"PRECIO COMBUSTIBLE X GALON":"","MONEDA":"Q","TOTAL_CONSUMIDO":(this.state.TOTAL.TOTAL_CONSUMIDO).toFixed(2),"NOMBRE_PILOTO":"","PROVEEDOR":"","COMBUSTIBLE":""}
    )
   
   
   
    this.setState({ CSV:CSV});
  }
    
  }



  UpdateState = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };



  exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "CONSUMO COMBUSTIBLE";
    const headers =[[  "No", "UNIDAD","FECHA CONSUMO","ODOMETRO", "GALONES","LITROS CONSUMIDOS","PRECIO COMBUSTIBLE X GALON","MONEDA", "TOTAL CONSUMIDO","NOMBRE PILOTO","PROVEEDOR", "COMBUSTIBLE"  ]]
 {/*'es-GT', { timeZone: 'UTC' } */}
    let data = this.state.dataFiltrada.map((rows,index)=> [index+1,rows.NOMBRE_UNIDAD,new  Date((rows.FECHA_CONSUMO)).toLocaleString(),rows.ODOMETRO,rows.CANTIDAD_CONSUMIDA,(rows.CANTIDAD_CONSUMIDA*3.785).toFixed(2),rows.PRECIO_COMBUSTIBLE,rows.MONEDA,rows.TOTAL_CONSUMIDO,rows.NOMBRE_PILOTO,rows.PROVEEDOR,rows.COMBUSTIBLE]);

    data=data.concat(
     [ ["TOTAL","","","",(this.state.TOTAL.CANTIDAD_CONSUMIDA).toFixed(2),(this.state.TOTAL.CANTIDAD_CONSUMIDA*3.785).toFixed(2),"","Q",(this.state.TOTAL.TOTAL_CONSUMIDO).toFixed(2),"","",""]]
    );



    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    const fecha=new  Date().toLocaleString(); {/*'es-GT', { timeZone: 'UTC' } */}
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(`CONSUMO_COMBUSTIBLE ${fecha}.pdf`);
  }

  // cambioEstado = async (e) => {
  //  const data = await fetchGet(`${process.env.REACT_APP_SERVER}/api/piloto`);
  //   this.setState({ dataFiltrada: data.data, data: data.data });
  //   const { name, value } = e.target;
  //   this.setState({
  //     [name]: value,
  //   });
  // };

  BuscarDatos = async(e) => {
    e.preventDefault();
    const patt = new RegExp(`${this.state.BuscarDatos}`, "gi");
     if(this.state.BuscarDatos && this.state.BuscarDatos.length>0)
{
  const datos = this.state.data.filter((dat) =>dat.NOMBRE_UNIDAD.toUpperCase()==this.state.BuscarDatos.toUpperCase());
  await this.setState({
    dataFiltrada: datos,
  });
}
else{
  const datos = this.state.data;
  await this.setState({
    dataFiltrada: datos,
  });
}

    let TotalReal=0,ConsumoReal=0;
    await   this.state.dataFiltrada.forEach((dat) =>{
      TotalReal+=dat.TOTAL_CONSUMIDO;
      ConsumoReal+=dat.CANTIDAD_CONSUMIDA;
    });
    
    await   this.setState({
      TOTAL:{TOTAL_CONSUMIDO:TotalReal,CANTIDAD_CONSUMIDA:ConsumoReal}
    })

    let CSV =this.state.dataFiltrada.map((rows,index)=>({No:index+1,UNIDAD:rows.NOMBRE_UNIDAD,FECHA_CONSUMO: new  Date((rows.FECHA_CONSUMO)).toLocaleString(),"ODOMETRO":rows.ODOMETRO,"GALONES":rows.CANTIDAD_CONSUMIDA,"LITROS_CONSUMIDOS":(rows.CANTIDAD_CONSUMIDA*3.785).toFixed(2),"PRECIO COMBUSTIBLE X GALON":rows.PRECIO_COMBUSTIBLE,"MONEDA":rows.MONEDA,"TOTAL_CONSUMIDO":rows.TOTAL_CONSUMIDO,"NOMBRE_PILOTO":rows.NOMBRE_PILOTO,"PROVEEDOR":rows.PROVEEDOR,"COMBUSTIBLE":rows.COMBUSTIBLE}));
    CSV=CSV.concat(
      {No:"TOTAL",UNIDAD:"",FECHA_CONSUMO:"","ODOMETRO":"","GALONES":(this.state.TOTAL.CANTIDAD_CONSUMIDA).toFixed(2),"LITROS_CONSUMIDOS":(this.state.TOTAL.CANTIDAD_CONSUMIDA*3.785).toFixed(2),"PRECIO COMBUSTIBLE X GALON":"","MONEDA":"Q","TOTAL_CONSUMIDO":(this.state.TOTAL.TOTAL_CONSUMIDO).toFixed(2),"NOMBRE_PILOTO":"","PROVEEDOR":"","COMBUSTIBLE":""}
    )
   
    this.setState({ CSV:CSV});
    
  };

cambioEstado = (e) => {
  const { name, value } = e.target;
  this.setState({
    [name]: value,
  });
};
  render() {
    const redireccion = this.props.Access("RP_CONSUMO_COMBUSTIBLE_ESTACION") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );


    return (
      <Fragment>
        {redireccion}




        <h1 className="text-center mb-5">DESCARGAR REPORTE</h1>
        {/* <div className="form-group ml-5">
              <label>FECHA INICIO:</label>
              <input
                type="DATE"
                name="FECHA_INICIO"
                className="form-control"
                placeholder="Fecha Inicio"
                onChange={this.UpdateState}
                defaultValue={this.state.NOMBRE_EMPRESA}
              />
            </div> */}
            <div className="ml-5">
              <label>FECHA INICIO:</label>
              </div>

            <div className="ml-5">

              <DatePicker  showTimeSelect
                timeIntervals={5}
                timeFormat="HH:mm"
                dateFormat="yyyy/MM/dd HH:mm" selected={this.state.FECHA_INICIO} onChange={date => this.setState({FECHA_INICIO:date})} />
                          </div>

                          <div className="ml-5">
              <label>FECHA FIN:</label>
              </div>

            <div className="ml-5">

              <DatePicker  showTimeSelect
                timeIntervals={5}
                timeFormat="HH:mm"
                dateFormat="yyyy/MM/dd HH:mm" selected={this.state.FECHA_FIN} onChange={date => this.setState({FECHA_FIN:date})} />
                          </div>
                          <div className="ml-5">

               <label>Estacion:</label>
              <Select
                onChange={this.updateStateSelectEstacion}
                options={this.state.Estaciones}
                isMulti={false}
                components={makeAnimated()}
                placeholder={"Seleccione Estacion"}
                getOptionLabel={(options) => options.NOMBRE_ESTACON}
                getOptionValue={(options) => options.ID_ESTACION}
                value={this.state.Estacion}
              /></div>
            <div className="form-group ml-5">
              {/* <label>FECHA FIN:</label>
              <input
                type="DATE"
                name="FECHA_FIN"
                className="form-control"
                placeholder="Fecha Final"
                onChange={this.UpdateState}
                defaultValue={this.state.NOMBRE_EMPRESA}
              /> */}
          <button onClick={this.Buscar}
          className="btn btn-link  float-right  ml-5 mr-5">
            BUSCAR POR FECHA
          </button>
            </div>
         

        <form class="form-inline " onSubmit={this.BuscarDatos}>
          <label className="ml-5 mr-5">
            <strong>Nombre Unidad:</strong>
          </label>
          <input
            class="form-control mr-sm-5"
            type="search"
            name="BuscarDatos"
            onChange={this.cambioEstado}
            defaultValue={this.state.BuscarDatos}
            placeholder=""
            aria-label="Search"
          />
          <button class="btn btn-outline-dark my-2 my-sm-0" type="submit">
            Filtrar
          </button>
        </form>

      
    
{/* <CSVLink data={csvData}>Download me</CSVLink>;
// or
<CSVDownload data={csvData} target="_blank" />; */}

{/* exportar excel */}
<div className=" ml-5 mt-2  my-2"> {/*'es-GT', { timeZone: 'UTC' } */}
<CSVLink data={this.state.CSV} class="btn btn-secondary m-2 my-sm-0" headers={this.state.headers}  filename={`CONSUMO_COMBUSTIBLE ${new  Date().toLocaleString()}.csv`}>
 EXPORTAR CVS
</CSVLink>

{/* exportar pdf */}
 <button class="btn btn-danger m-2 my-sm-0" onClick={() => this.exportPDF()}>EXPORTAR PDF</button>



 <Fragment>
 {/*'es-GT', { timeZone: 'UTC' } */}
<ExcelFile  element={<button class="btn btn-success m-2 my-sm-0">EXPORTAR EXCEL</button>}
filename={`CONSUMO_COMBUSTIBLE ${new  Date().toLocaleString()}`} 

>
                <ExcelSheet data={this.state.CSV} name={`hoja1`}>
                <ExcelColumn  label="No" value= "No" />
                
 <ExcelColumn  label="UNIDAD" value= "UNIDAD" />          
 <ExcelColumn  label="FECHA CONSUMO" value= "FECHA_CONSUMO" />
 <ExcelColumn  label="ODOMETRO" value= "ODOMETRO" />
 <ExcelColumn  label="GALONES" value= "GALONES" />
 <ExcelColumn  label="LITROS CONSUMIDOS" value= "LITROS_CONSUMIDOS" />
 <ExcelColumn  label="PRECIO COMBUSTIBLE X GALON" value= "PRECIO COMBUSTIBLE X GALON" />
 <ExcelColumn  label="MONEDA" value= "MONEDA" />
 <ExcelColumn  label="TOTAL CONSUMIDO" value= "TOTAL_CONSUMIDO" />
 <ExcelColumn  label="NOMBRE PILOTO" value= "NOMBRE_PILOTO" />
 <ExcelColumn  label="PROVEEDOR" value= "PROVEEDOR" />
 <ExcelColumn  label="COMBUSTIBLE" value= "COMBUSTIBLE"/>
{/*                 
                
                    <ExcelColumn label="Name" value="name"/>
                    <ExcelColumn label="Wallet Money" value="amount"/>
                    <ExcelColumn label="Gender" value="sex"/>
                    <ExcelColumn label="Marital Status"
                                 value={(col) => col.is_married ? "Married" : "Single"}/> */}
                </ExcelSheet>
            </ExcelFile>
</Fragment>

 </div>

{this.state.dataFiltrada && (
  <div className="responsibaTableReporte">
  <table>
    <tr>
    <td>No.</td>
<td>UNIDAD</td>
<td>FECHA CONSUMO</td>
<td>ODOMETRO</td>
<td>CANTIDAD CONSUMIDA</td>
<td>LITROS</td>
<td>PRECIO COMBUSTIBLE X GALON</td>
<td>MONEDA</td>
<td>TOTAL CONSUMIDO</td>
<td>NOMBRE PILOTO</td>
<td>PROVEEDOR</td>
<td>C0MBUSTIBLE</td>
    </tr>
            {this.state.dataFiltrada.map((item,index) => {
              const { NOMBRE_UNIDAD } = item;
              return (
                <tr>
                <td>{index+1}</td>
                 <td>{item.NOMBRE_UNIDAD}</td>
                 <td>{new  Date((item.FECHA_CONSUMO)).toLocaleString()}</td>
                  <td>{item.ODOMETRO}</td>
                  <td>{item.CANTIDAD_CONSUMIDA}</td>
                  <td>{(item.CANTIDAD_CONSUMIDA*3.785).toFixed(2)}</td>
                  <td>{item.PRECIO_COMBUSTIBLE}</td>
                  <td>{item.MONEDA}</td>
                  <td>{item.TOTAL_CONSUMIDO}</td>
                  <td>{item.NOMBRE_PILOTO}</td>
                  <td>{item.PROVEEDOR}</td>
                  <td>{item.COMBUSTIBLE}</td>

    </tr>
              );
            })}

            
            <tr>
                  <td ><strong>TOTAL</strong></td>
                  <td ></td>
                  <td></td>
                  <td></td>
                  <td><strong>{this.state.TOTAL.CANTIDAD_CONSUMIDA.toFixed(2)}</strong></td>
                  <td><strong>{(this.state.TOTAL.CANTIDAD_CONSUMIDA*3.785).toFixed(2)}</strong></td>
                  <td></td>
                  <td><strong>Q</strong></td>
                  <td><strong>{this.state.TOTAL.TOTAL_CONSUMIDO.toFixed(2)}</strong></td>
                  <td></td>
                  <td></td>
                  <td></td>

                  </tr>

</table>
          </div>
        )}
      </Fragment>
    );
  }
}

export default Reporte_Uno;
