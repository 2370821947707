import React, { Component, Fragment } from "react";
import { fetchPost,fetchGet, fetchPut } from "../../../utils/Fetch";
import { withRouter, Redirect } from "react-router-dom";

const initialState = {
  ID_CONSUMO_COMBUSTIBLE:null
  ,ID_ASIGNACION_COMBUSTIBLE:""
  ,CANTIDAD_ASIGNADA:""
  ,ID_TARJETA:"",
  Estacion:"",
  Empresa:"",
  PROCESO:false
  ,ESTADO:true,
  FECHA_CONSUMO:new Date()
  ,ID_ESTACION:1,
  CANTIDAD_ACTUAL:""
  ,ID_USUARIO:1,
  PAGADO:false,
  ID_EMPRESA:1,
  MONEDA:"Q",
  PROVEEDOR:'Smartgast',
  display:"divMostrar",
  displayReporte:"divNoMostrar",
  displayReporteTicket:"divNoMostrar",
  Combustible:{NOMBRE_COMBUSTIBLE:""}
};

class ConsumoCombustibleNuevo extends Component {
  state = {
    ...initialState,
  };

  clearState = () => {
    this.setState({
      ...initialState,
    });
  };

  async componentDidMount() {
    
    if(this.props.auth &&  this.props.auth[0] ){
    this.setState({
      Estacion:this.props.auth[0].NOMBRE_ESTACON,
      Empresa:this.props.auth[0].NOMBRE_EMPRESA,
    })
  }
    const { id } = this.props.match.params;

    const data = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/asignacioncombustible/${id}`
    );
    this.setState({ ...data.data[0] });

    const dataC = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/consumocombustible/${id}`
    );
    this.setState({ ...dataC.data[0] });


    const dataTarjeta = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/tarjeta/asingacion/false`
    );
    const datacombustible = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/combustible`
    );
    const datauni = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/unidad`
    );

    const dataestacion = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/estacion`
    );

    // const Roles=dataRol.data;
    // const Rol=Roles.find(rol=>rol.ID_ROL===data.data[0].ID_ROL)
//     console.log(data.data[0])

// console.log(this.state.ID_TARJETA,
//   data.data[0].ID_COMBUSTIBLE,
//   data.data[0].ID_UNIDAD)

   const Tarjetas=dataTarjeta.data;
   const Combustibles=datacombustible.data;
   const Unidades= datauni.data;
   const Tarjeta=Tarjetas.find(dat=>dat.ID_TARJETA===this.state.ID_TARJETA)
   const Combustible=Combustibles.find(dat=>dat.ID_COMBUSTIBLE===this.state.ID_COMBUSTIBLE)
   const Unidad=Unidades.find(dat=>dat.ID_UNIDAD===this.state.ID_UNIDAD)
  
   const dataDetalle = await fetchGet(
    `${process.env.REACT_APP_SERVER}/api/detalleconsumocombustible/consumo/${id}`
  );

    this.setState({ Tarjetas,
      Combustibles,
      Unidades,
      Tarjeta,
      Combustible,
      Unidad,
      Estaciones:dataestacion.data,
      dataDetalle: dataDetalle.data,
    });
  
  
    if(this.state.CANTIDAD_ASIGNADA==="")
{
  this.setState({
CANTIDAD_ASIGNADA:this.state.GALONES_COMBUSTIBLE,
CANTIDAD_ACTUAL:this.state.GALONES_COMBUSTIBLE,
  })


}

  }


  UpdateState = (e) => {
    let { name, value } = e.target;

    value=(value<0)?0:value;

    this.setState({
      [name]: ((this.state.CANTIDAD_ACTUAL)>=value)?value:0,
    });
  };

  validarForm = () => {
    const {CANTIDAD_CONSUMIDA,PROCESO} = this.state;
    const noValido = !CANTIDAD_CONSUMIDA||PROCESO;
    return noValido;
  };

  Imprimir = async () => {
    
await    this.setState({
      display:"divNoMostrar",
  displayReporte:"divMostrar"
    });

  await window.print();

  await this.setState({
    display:"divMostrar",
displayReporte:"divNoMostrar"
  });
}


ImprimirTicket = async () => {
  await    this.setState({
        display:"divNoMostrar",
    displayReporteTicket:"divMostrar"
      });
  
    await window.print();
  
    await this.setState({
      display:"divMostrar",
      displayReporteTicket:"divNoMostrar"
    });
  }


 buscar = async ()=>{
  const dataDetalle = await fetchGet(
    `${process.env.REACT_APP_SERVER}/api/detalleconsumocombustible/consumo/${this.state.ID_ASIGNACION_COMBUSTIBLE}`
  );

    this.setState({dataDetalle: dataDetalle.data
    });


    const dataC = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/consumocombustible/${this.state.ID_ASIGNACION_COMBUSTIBLE}`
    );
    this.setState({ ...dataC.data[0] });
}

  CrearConsumoCombustible = async (e) => {
    e.preventDefault();

await this.setState({
  PROVEEDOR:`${this.props.auth[0].NOMBRE_USUARIO} ${this.props.auth[0].NOMBRE_ESTACON}`,
  PROCESO:true
})


await this.setState({
  FECHA_CONSUMO:new Date()
})

    if(!this.state.ID_CONSUMO_COMBUSTIBLE)
    {const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/consumocombustible`,
      this.state
    );
    
    // detalleconsumocombustible
    this.setState({ data: data.data });
 //   alert(data.message);
}
    await this.buscar();



    const dataActu = await fetchPut(
      `${process.env.REACT_APP_SERVER}/api/consumocombustible/consumo`,
      this.state
    );
    
    // detalleconsumocombustible
    this.setState({ data: dataActu.data });
  //  alert(dataActu.message);


  debugger
const dd =await fetchPost(
    `${process.env.REACT_APP_SERVER}/api/detalleconsumocombustible`,
    this.state
  );

 // alert(dd.message);
  await this.buscar();
  




await this.ImprimirTicket();

  this.props.history.push("/Autorizacion");

  };

  render() {
    const redireccion = this.props.Access("CrearAutorizacion") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );

    return (
     
     <Fragment>
      <div className={`${this.state.displayReporteTicket} comprobante`}>
      <p class="centrado comprobante">Estacion
                <br/>{this.state.Estacion}</p>
                
                <p class="izquieda comprobante">
  
                Cliente
                <br/>{this.state.NOMBRE_EMPRESA}</p>
            <table>
                {/* <thead>
                    <tr>
                        <th class="cantidad">CANT</th>
                        <th class="producto">PRODUCTO</th>
                        <th class="precio">$$</th>
                    </tr>
                </thead> */}
                <tbody>
                   
                <tr>
                        <td class="Unidad">Ticket</td>
                        <td class=""></td>
                        <td class="NOMBRE_UNIDAD">{this.state.ID_CONSUMO_COMBUSTIBLE}</td>
                       
                    </tr> <tr>
                        <td class="Unidad">Unidad</td>
                        <td class=""></td>
                        <td class="NOMBRE_UNIDAD">{this.state.NOMBRE_UNIDAD}</td>
                       
                    </tr>
                   
                    <tr>
                        <td class="Piloto">Piloto</td>
                        <td class=""></td>
                        <td class="NOMBRE_PILOTO">{this.state.NOMBRE_PILOTO}</td>
                       
                    </tr>
                   
                    <tr>
                       <td class="Odometro">Kms/Millas</td>
                       <td class=""></td>
                        <td class="ODOTOMETRO">{this.state.ODOTOMETRO}</td>
                    </tr>
                    
                    <tr>
                       <td class="Combustible">Comb.</td>
                       <td class=""></td>
                        <td class="NOMBRE_COMBUSTIBLE">{this.state.Combustible.NOMBRE_COMBUSTIBLE}</td>
                    </tr>
                    
                    <tr>
                       <td class="GalDespach">Gal. Desp.</td>
                        <td class=""></td>
                        <td class="CANTIDAD_CONSUMIDA">{this.state.CANTIDAD_CONSUMIDA}</td>
                    </tr>
                  
                    <tr>
                       <td class="PrecioUnit">Precio Unit</td>
                        <td class=" centrado">Q</td>
                        <td class="PRECIO_COMBUSTIBLE">{this.state.PRECIO_COMBUSTIBLE}</td>
                    </tr>
                  
                    <tr>
                       <td class="VtaTotal">Vta Total</td>
                        <td class=" centrado">Q</td>
                        <td class="VentaTotal">{(this.state.PRECIO_COMBUSTIBLE * parseFloat(this.state.CANTIDAD_CONSUMIDA)).toFixed(2)}</td>
                    </tr>
                    {/* <tr>
                        <td class="cantidad">1.00</td>
                        <td class="producto">COCA COLA 600 ML</td>
                        <td class="precio">$10.00</td>
                    </tr>
                    <tr>
                        <td class="cantidad"></td>
                        <td class="producto">TOTAL</td>
                        <td class="precio">$28.50</td>
                    </tr> */}
                </tbody>
            </table>
            <br/>
            <p class="centrado comprobante">____________________________</p>
            <p class="centrado comprobante">Firma</p>
            <p class="izquierda comprobante">
                <br/>Fecha y Hora de Despacho
                </p>
            <p class="izquierda comprobante">{this.state.FECHA_CONSUMO.toLocaleString()}</p>
            <p class="izquierda comprobante">
                <br/>Fecha y Hora de Impresión
                </p> 
            <p class="izquierda comprobante">{new Date().toLocaleString()}</p>
        </div>
       <div className={`${this.state.displayReporte} comprobante`}>
               <p class="centrado comprobante">Estacion
                <br/>{this.state.Estacion}</p>
            <table>
                {/* <thead>
                    <tr>
                        <th class="cantidad">CANT</th>
                        <th class="producto">PRODUCTO</th>
                        <th class="precio">$$</th>
                    </tr>
                </thead> */}
                <tbody>
                   <tr>
                       <td class="Combustible">Combustible</td>
                       <td class="espacio"></td>
                        <td class="NOMBRE_COMBUSTIBLE">{this.state.Combustible.NOMBRE_COMBUSTIBLE}</td>
                    </tr>
                    <tr>
                        <td class="Unidad">Unidad</td>
                        <td class="espacio"></td>
                        <td class="NOMBRE_UNIDAD">{this.state.NOMBRE_UNIDAD}</td>
                    </tr>
                    <tr>
                        <td class="espacio"></td>
                        <td class="espacio"></td>
                        <td class="espacio"></td>
                    </tr>
                    <tr>
                       <td class="Odometro">Odometro</td>
                       <td class="espacio"></td>
                        <td class="ODOTOMETRO">{this.state.ODOTOMETRO}</td>
                    </tr>
                    <tr>
                        <td class="espacio"></td>
                        <td class="espacio"></td>
                        <td class="espacio"></td>
                    </tr>
                    <tr>
                    <td class="Cantidad">Galones</td>
                        <td class="espacio"></td>
                        <td class="CANTIDAD_ASIGNADA">{this.state.CANTIDAD_ASIGNADA}</td>

                    </tr>
                    <tr>
                        <td class="Cantidad">Total</td>
                        <td class="espacio">Q</td>
                        <td class="CANTIDAD_ASIGNADA">{(this.state.PRECIO_COMBUSTIBLE * parseFloat(this.state.CANTIDAD_ASIGNADA)).toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td class="espacio"></td>
                        <td class="espacio"></td>
                        <td class="espacio"></td>
                    </tr>
                    <tr>
                        <td class="espacio"></td>
                        <td class="espacio"></td>
                        <td class="espacio"></td>
                    </tr>
                    {/* <tr>
                        <td class="cantidad">1.00</td>
                        <td class="producto">COCA COLA 600 ML</td>
                        <td class="precio">$10.00</td>
                    </tr>
                    <tr>
                        <td class="cantidad"></td>
                        <td class="producto">TOTAL</td>
                        <td class="precio">$28.50</td>
                    </tr> */}
                </tbody>
            </table>
            <p class="centrado"><br/><br/></p>
        </div>
       <div className={this.state.display}>
        {redireccion}
        <h1 className="text-center mb-5">Nuevo Consumo Combustible</h1>

        <div className="row justify-content-center">
          <form
            className="col-md-8 col-sm-12"
            onSubmit={(e) => this.CrearConsumoCombustible(e)}
          >
            <div className="form-group">
              <label>NUMERO_TARJETA</label>
              <input
                type="text"
                name="NUMERO_TARJETA"
                className="form-control"
                placeholder="NUMERO_TARJETA"
                readOnly
                onChange={this.UpdateState}
                defaultValue={this.state.NUMERO_TARJETA}
              />
            </div>

            <div className="form-group">
              <label>NOMBRE_COMBUSTIBLE</label>
              <input
                type="text"
                name="NOMBRE_COMBUSTIBLE"
                className="form-control"
                placeholder="NOMBRE_COMBUSTIBLE"
                readOnly
                onChange={this.UpdateState}
                defaultValue={this.state.NOMBRE_COMBUSTIBLE}
              />
            </div>

            <div className="form-group">
              <label>PRECIO_COMBUSTIBLE</label>
              <input
                type="number"
                name="PRECIO_COMBUSTIBLE"
                className="form-control"
                placeholder="PRECIO_COMBUSTIBLE"
                readOnly
                onChange={this.UpdateState}
                defaultValue={this.state.PRECIO_COMBUSTIBLE}
              />
            </div>
            <div className="form-group">
              <label>CANTIDAD_ASIGNADA</label>
              <input
                type="number"
                name="CANTIDAD_ASIGNADA"
                className="form-control"
                placeholder="CANTIDAD_ASIGNADA"
                readOnly
                onChange={this.UpdateState}
                defaultValue={this.state.CANTIDAD_ASIGNADA}
              />
            </div>

            <div className="form-group">
              <label>NOMBRE_PILOTO</label>
              <input
                type="text"
                name="NOMBRE_PILOTO"
                className="form-control"
                placeholder="NOMBRE_PILOTO"
                readOnly
                onChange={this.UpdateState}
                defaultValue={this.state.NOMBRE_PILOTO}
              />
            </div>
            <div className="form-group">
              <label>NOMBRE_UNIDAD</label>
              <input
                type="text"
                name="NOMBRE_UNIDAD"
                className="form-control"
                placeholder="NOMBRE_UNIDAD"
                readOnly
                onChange={this.UpdateState}
                defaultValue={this.state.NOMBRE_UNIDAD}
              />
            </div>


            <div className="form-group">
              <label>CANTIDAD_ACTUAL</label>
              <input
                type="number"
                name="CANTIDAD_ACTUAL"
                className="form-control"
                placeholder="CANTIDAD_ACTUAL"
                readOnly
                onChange={this.UpdateState}
                defaultValue={this.state.CANTIDAD_ACTUAL}
              />
            </div>
            <div className="form-group">
              <label>CANTIDAD_CONSUMIDA</label>
              <input
                type="number"
                name="CANTIDAD_CONSUMIDA"
                className="form-control"
                placeholder="CANTIDAD_CONSUMIDA"
                onChange={this.UpdateState}
                value={this.state.CANTIDAD_CONSUMIDA}
                min={0}
              />
            </div>
            <button
              disabled={this.validarForm()}
              type="button"
              onClick={this.CrearConsumoCombustible}
              className="btn btn-success float-right"
            >
              Agregar Combustible
            </button>
            <button  type="button" className="btn btn-success float-left"
              onClick={this.Imprimir}>
    Imprimir
    </button>
          </form>
            {/* <a href="javascript:window.print()"> Imprimir</a> */}
        </div>
       
      </div>
     </Fragment>
    );
  }
}

export default withRouter(ConsumoCombustibleNuevo);
