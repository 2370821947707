import React, { Component, Fragment } from "react";
import { fetchPost } from "../../../utils/Fetch";
import { withRouter, Redirect } from "react-router-dom";

const initialState = {
  ID_ESTACION: "",
  ID_EMPRESA: "",
NOMBRE_ESTACONR: "",
DIRECCION_ESTACION: "",
TELEFONO_ESTACION: "",
ESTADO:true
};

class EstacionNuevo extends Component {
  state = {
    ...initialState,
  };

  clearState = () => {
    this.setState({
      ...initialState,
    });
  };

  UpdateState = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  validarForm = () => {
    const {NOMBRE_ESTACONR,DIRECCION_ESTACION,TELEFONO_ESTACION} = this.state;
    const noValido = ! NOMBRE_ESTACONR ||! DIRECCION_ESTACION ||! TELEFONO_ESTACION;
    return noValido;
  };

  CrearEstacion = async (e) => {
    e.preventDefault();

    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/estacion`,
      this.state
    );
    this.setState({ data: data.data });
    alert(data.message);
    this.props.history.push("/estacion");
  };

  render() {
    const redireccion = this.props.Access("CrearEstaciones") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );

    return (
      <Fragment>
        {redireccion}
        <h1 className="text-center mb-5">Nueva estacion</h1>

        <div className="row justify-content-center">
          <form
            className="col-md-8 col-sm-12"
            onSubmit={(e) => this.CrearEstacion(e)}
          >
            <div className="form-group">
              <label>Nombre:</label>
              <input
                type="text"
                name="NOMBRE_ESTACONR"
                className="form-control"
                placeholder="Nombre"
                onChange={this.UpdateState}
                defaultValue={this.state.NOMBRE_ESTACONR}
              />
            </div>
            <div className="form-group">
              <label>Direccion:</label>
              <input
                type="text"
                name="DIRECCION_ESTACION"
                className="form-control"
                placeholder="Direccion"
                onChange={this.UpdateState}
                defaultValue={this.state.DIRECCION_ESTACION}
              />
            </div>  <div className="form-group">
              <label>Telefono:</label>
              <input
                type="text"
                name="TELEFONO_ESTACION"
                className="form-control"
                placeholder="Telefono"
                onChange={this.UpdateState}
                defaultValue={this.state.TELEFONO_ESTACION}
              />
            </div>
            <button
              disabled={this.validarForm()}
              type="submit"
              className="btn btn-success float-right"
            >
              Nueva Estacion
            </button>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(EstacionNuevo);
