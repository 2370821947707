import React, { Component, Fragment } from "react";
import { Redirect,withRouter } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchGet,fetchDelete } from "../../../utils/Fetch";



const estadoInicial = { BuscarDatos: "", data: null,estado:"Re Activar" };

class Reporte_Uno extends Component {
  constructor(props) {
    super(props);
    this.state = { data: estadoInicial,dataFiltrada:[], CSV:[],FECHA_INICIO: new Date(),
      FECHA_FIN:new Date()};
  }

  Buscar = async () =>{

   
    

    // const CSV =this.state.dataFiltrada.map(rows=>({NOMBRE_UNIDAD:rows.NOMBRE_UNIDAD,FECHA_CONSUMO: new  Date((rows.FECHA_CONSUMO)).toLocaleString('es-GT', { timeZone: 'UTC' }),"ODOMETRO":rows.ODOMETRO,"CANTIDAD_CONSUMIDA":rows.CANTIDAD_CONSUMIDA,"PRECIO_COMBUSTIBLE":rows.PRECIO_COMBUSTIBLE,"MONEDA":rows.MONEDA,"TOTAL_CONSUMIDO":rows.TOTAL_CONSUMIDO,"NOMBRE_PILOTO":rows.NOMBRE_PILOTO,"PROVEEDOR":rows.PROVEEDOR,"COMBUSTIBLE":rows.COMBUSTIBLE}));
    // this.setState({ CSV:CSV});

    
  }

  async componentDidMount() {
    const { id } = this.props.match.params;

    this.setState({id:id});
    const data = await fetchGet(`${process.env.REACT_APP_SERVER}/api/reportes/${id}`);

    this.setState({ dataFiltrada: data.data, data: data.data});
  }

  UpdateState = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };



  

  // cambioEstado = async (e) => {
  //  const data = await fetchGet(`${process.env.REACT_APP_SERVER}/api/piloto`);
  //   this.setState({ dataFiltrada: data.data, data: data.data });
  //   const { name, value } = e.target;
  //   this.setState({
  //     [name]: value,
  //   });
  // };

  
  Eliminar = async (ID_DETALLE_CONSUMO_COMBUSTIBLE) => {
    const data = await fetchDelete(
      `${process.env.REACT_APP_SERVER}/api/reportes/${ID_DETALLE_CONSUMO_COMBUSTIBLE}`
    );
    alert(data.message);
    const dataGet = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/reportes/${this.state.id}`
    );
    this.setState({ dataFiltrada: dataGet.data, data: dataGet.data });
  };

  BuscarDatos = (e) => {
    e.preventDefault();
    const patt = new RegExp(`${this.state.BuscarDatos}`, "gi");
    const datos = this.state.data.filter((dat) => patt.exec(dat.NOMBRE_UNIDAD));

  

    this.setState({
      dataFiltrada: datos,
    });


    // const CSV =this.state.dataFiltrada.map(rows=>({NOMBRE_UNIDAD:rows.NOMBRE_UNIDAD,FECHA_CONSUMO: new  Date((rows.FECHA_CONSUMO)).toLocaleString('es-GT', { timeZone: 'UTC' }),"ODOMETRO":rows.ODOMETRO,"CANTIDAD_CONSUMIDA":rows.CANTIDAD_CONSUMIDA,"PRECIO_COMBUSTIBLE":rows.PRECIO_COMBUSTIBLE,"MONEDA":rows.MONEDA,"TOTAL_CONSUMIDO":rows.TOTAL_CONSUMIDO,"NOMBRE_PILOTO":rows.NOMBRE_PILOTO,"PROVEEDOR":rows.PROVEEDOR,"COMBUSTIBLE":rows.COMBUSTIBLE}));
    // this.setState({ CSV:CSV});

  };

cambioEstado = (e) => {
  const { name, value } = e.target;
  this.setState({
    [name]: value,
  });
};
  render() {
    const redireccion = this.props.Access("RevertirCombustibleVer") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );


    return (
      <Fragment>
        {redireccion}




        <h1 className="text-center mb-5">REPORTE</h1>
        {/* <div className="form-group ml-5">
              <label>FECHA INICIO:</label>
              <input
                type="DATE"
                name="FECHA_INICIO"
                className="form-control"
                placeholder="Fecha Inicio"
                onChange={this.UpdateState}
                defaultValue={this.state.NOMBRE_EMPRESA}
              />
            </div> */}
            {/* <div className="ml-5">
              <label>FECHA INICIO:</label>
              </div>

            <div className="ml-5">

              <DatePicker  showTimeSelect
                timeIntervals={5}
                timeFormat="HH:mm"
                dateFormat="yyyy/MM/dd HH:mm" selected={this.state.FECHA_INICIO} onChange={date => this.setState({FECHA_INICIO:date})} />
                          </div>

                          <div className="ml-5">
              <label>FECHA FIN:</label>
              </div>

            <div className="ml-5">

              <DatePicker  showTimeSelect
                timeIntervals={5}
                timeFormat="HH:mm"
                dateFormat="yyyy/MM/dd HH:mm" selected={this.state.FECHA_FIN} onChange={date => this.setState({FECHA_FIN:date})} />
                          </div>


            <div className="form-group ml-5">
              {/* <label>FECHA FIN:</label>
              <input
                type="DATE"
                name="FECHA_FIN"
                className="form-control"
                placeholder="Fecha Final"
                onChange={this.UpdateState}
                defaultValue={this.state.NOMBRE_EMPRESA}
              /> */}
          {/* <button onClick={this.Buscar}
          className="btn btn-link  float-right  ml-5 mr-5">
            BUSCAR POR FECHA
          </button>
            </div> 
          */}

        <form class="form-inline " onSubmit={this.BuscarDatos}>
          <label className="ml-5 mr-5">
            <strong>Nombre Unidad:</strong>
          </label>
          <input
            class="form-control mr-sm-5"
            type="search"
            name="BuscarDatos"
            onChange={this.cambioEstado}
            defaultValue={this.state.BuscarDatos}
            placeholder=""
            aria-label="Search"
          />
          <button class="btn btn-outline-dark my-2 my-sm-0" type="submit">
            Filtrar
          </button>
        </form>

      
    
{/* <CSVLink data={csvData}>Download me</CSVLink>;
// or
<CSVDownload data={csvData} target="_blank" />; */}



{this.state.dataFiltrada && (
          <div className="ml-5 mr-5">
            <div className="row border">
              <div className="col-md-1 col-xs-1">NOMBRE UNIDAD</div>
              <div className="col-md-2 d-none  d-sm-none d-md-block ">FECHA CONSUMO</div>
              <div className="col-md-1 d-none  d-sm-none d-md-none d-lg-block">ODOMETRO</div>
              <div className="col-md-1 d-none  d-sm-none d-md-none d-lg-block">CANTIDAD CONSUMIDA</div>
              <div className="col-md-1 d-none  d-sm-none d-md-none d-lg-block">PRECIO COMBUSTIBLE</div>
              <div className="col-md-1 d-none  d-sm-none d-md-none d-lg-block">MONEDA</div>
              <div className="col-md-1 d-none  d-sm-none d-md-none d-lg-block">TOTAL CONSUMIDO</div>
              <div className="col-md-1 d-none  d-sm-none d-md-none d-lg-block">NOMBRE PILOTO</div>
              <div className="col-md-1 d-none  d-sm-none d-md-none d-lg-block">PROVEEDOR</div>
              <div className="col-md-1 d-none  d-sm-none d-md-none d-lg-block">C0MBUSTIBLE</div>
              <div className="col-sm-1 col-xs-1">OPCIONES</div>
              
            </div>
            {this.state.dataFiltrada.map((item) => {
              const { NOMBRE_UNIDAD } = item;
              return (
                <div className="row border" >
                  <div className="col-md-1 col-xs-1">{item.NOMBRE_UNIDAD}</div>
                 <div className="col-md-2 d-none  d-sm-none d-md-block ">{new  Date((item.FECHA_CONSUMO)).toLocaleString()}</div>            {/*'es-GT', { timeZone: 'UTC' } */}
                  <div className="col-md-1 d-none  d-sm-none d-md-none d-lg-block">{item.ODOMETRO}</div>
                  <div className="col-md-1 d-none  d-sm-none d-md-none d-lg-block">{item.CANTIDAD_CONSUMIDA}</div>
                  <div className="col-md-1 d-none  d-sm-none d-md-none d-lg-block">{item.PRECIO_COMBUSTIBLE}</div>
                  <div className="col-md-1 d-none  d-sm-none d-md-none d-lg-block">{item.MONEDA}</div>
                  <div className="col-md-1 d-none  d-sm-none d-md-none d-lg-block">{item.TOTAL_CONSUMIDO}</div>
                  <div className="col-md-1 d-none  d-sm-none d-md-none d-lg-block">{item.NOMBRE_PILOTO}</div>
                  <div className="col-md-1 d-none  d-sm-none d-md-none d-lg-block">{item.PROVEEDOR}</div>
                  <div className="col-md-1 d-none  d-sm-none d-md-none d-lg-block">{item.COMBUSTIBLE}</div>

                  <div className="col-sm-1 col-xs-1">

                  {this.props.Access("RevertirCombustibleEliminar")  && (
                      <button
                        onClick={() => {
                          if (window.confirm("Seguro que deseas revertir")) {
                            this.Eliminar(item.ID_DETALLE_CONSUMO_COMBUSTIBLE);
                          }
                        }}
                        type="button"
                        className="btn btn-danger m-1 "
                      >
                        &times; REVERTIR
                      </button>
                    )}
                  </div>
                  {/* </td> */}
                  {/* </tr> */}
                </div>
              );
            })}
          </div>
        )}
      </Fragment>
    );
  }
  
}

export default withRouter(Reporte_Uno);
