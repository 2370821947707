
  import React, { Component, Fragment } from "react";
  import { fetchGet, fetchPut } from "../../../utils/Fetch";
  import { withRouter, Redirect } from "react-router-dom";
  // import Select from "react-select";
  // import makeAnimated from "react-select/animated";

  const initialState = {
    ID_PRECIO_COMBUSTIBLE_DIA:"", 
    ID_COMBUSTIBLE:"", 
    PRECIO_ANTERIOR:"", 
    PRECIO_ACTUAL:"", 
    FECHA_INGRESO:"", 
    ID_USUARIO:"", 
    ID_ESTACION:"", 
    ESTADO:"",
    NOMBRE_COMBUSTIBLE:"", 
    NOMBRE_ESTACON:"", 
    TELEFONO_ESTACION:"",
    NOMBRE_USUARIO:"",
    USUARIO_USUARIO:""
  };
  
  class PrecioDiaEditar extends Component {
    state = {
      ...initialState,
    };
  
    clearState = () => {
      this.setState({
        ...initialState,
      });
    };
  
    UpdateState = (e) => {
      const { name, value } = e.target;
      this.setState({
        [name]: value,
      });
    };
  
    validarForm = () => {
      const {ID_PRECIO_COMBUSTIBLE_DIA,
        ID_COMBUSTIBLE,
        PRECIO_ANTERIOR,
        PRECIO_ACTUAL,
        FECHA_INGRESO,
        ID_USUARIO,
        ID_ESTACION,
        ESTADO,
        NOMBRE_COMBUSTIBLE,
        NOMBRE_ESTACON,
        TELEFONO_ESTACION,
        NOMBRE_USUARIO,
        USUARIO_USUARIO} = this.state;
      const noValido = 
      !ID_PRECIO_COMBUSTIBLE_DIA||
      !ID_COMBUSTIBLE||
      !PRECIO_ANTERIOR||
      !PRECIO_ACTUAL||
      !FECHA_INGRESO||
      !ID_USUARIO||
      !ID_ESTACION||
      !ESTADO||
      !NOMBRE_COMBUSTIBLE||
      !NOMBRE_ESTACON||
      !TELEFONO_ESTACION||
      !NOMBRE_USUARIO||
      !USUARIO_USUARIO;
      return noValido;
    };
  
    async componentDidMount() {
      const { id } = this.props.match.params;
  
      const data = await fetchGet(
        `${process.env.REACT_APP_SERVER}/api/preciocombustibledia/${id}`
      );
      this.setState({ ...data.data[0] });


      // const dataRol = await fetchGet(
      //   `${process.env.REACT_APP_SERVER}/api/roles`
      // );
      
      // const Roles=dataRol.data;
      // const Rol=Roles.find(rol=>rol.ID_ROL===data.data[0].ID_ROL)

      // this.setState({Rol, Roles });
    }
    // updateStateSelectRol = (Rol) => {
    //   this.setState({
    //     Rol,
    //     ID_ROL:Rol.ID_ROL,
    //   });
    // };
  
    PrecioDiaEditar = async (e) => {
      e.preventDefault();
  
      const data = await fetchPut(
        `${process.env.REACT_APP_SERVER}/api/preciocombustibledia/${this.state.ID_PRECIO_COMBUSTIBLE_DIA}`,
        this.state
      );
      this.setState({ data: data.data });
      alert(data.message);
      this.props.history.push("/PrecioCombustibleDia");
    };
  
    render() {
      const redireccion = this.props.Access("ModificarPrecioCombustibleDia") ? (
        ""
      ) : (
        <Redirect to="/login" />
      );
  
      const mensaje = this.props.modificar ? (
        "Editar Precio Combustible Dia"
      ) : (
        "Detalles de Precio Combustible Dia"
      );
      return (
        <Fragment>
          {redireccion}
<h1 className="text-center mb-5">{mensaje}</h1>
  
          <div className="row justify-content-center">
            <form
              className="col-md-8 col-sm-12"
              onSubmit={(e) => this.PrecioDiaEditar(e)}
            >
              <div className="form-group">
                <label>NOMBRE_COMBUSTIBLE:</label>
                <input
                  type="text"
                  name="NOMBRE_COMBUSTIBLE"
                  className="form-control"
                  placeholder="NOMBRE_COMBUSTIBLE"
                  onChange={this.UpdateState}
                  readOnly={!this.props.modificar}
                  defaultValue={this.state.NOMBRE_COMBUSTIBLE}
                />
              </div>
              <div className="form-group">
                <label>NOMBRE_ESTACION:</label>
                <input
                  type="text"
                  name="NOMBRE_ESTACON"
                  className="form-control"
                  placeholder="NOMBRE_ESTACON"
                  onChange={this.UpdateState}
                  defaultValue={this.state.NOMBRE_ESTACON}
                  readOnly={!this.props.modificar}
                />
              </div>
              <div className="form-group">
                <label>TELEFONO_ESTACION:</label>
                <input
                  type="text"
                  name="TELEFONO_ESTACION"
                  className="form-control"
                  placeholder="TELEFONO_ESTACION"
                  onChange={this.UpdateState}
                  defaultValue={this.state.TELEFONO_ESTACION}
                  readOnly={!this.props.modificar}
                />
{/* 
<Select
                onChange={this.updateStateSelectRol}
                options={this.state.Roles}
                isMulti={false}
                isDisabled={!this.props.modificar}
                components={makeAnimated()}
                placeholder={"Seleccione el Rol"}
                getOptionLabel={(options) => options.NOMBRE_ROL}
                getOptionValue={(options) => options.ID_ROL}
                value={this.state.Rol}
              /> */}


              </div>
              <div className="form-group">
                <label>PRECIO_ANTERIOR:</label>
                <input
                  type="number"
                  name="PRECIO_ANTERIOR"
                  className="form-control"
                  placeholder="PRECIO_ANTERIOR"
                  onChange={this.UpdateState}
                  readOnly={!this.props.modificar}
                  defaultValue={this.state.PRECIO_ANTERIOR}
                />
              </div>
              <div className="form-group">
                <label>PRECIO_ACTUAL:</label>
                <input
                  type="number"
                  name="PRECIO_ACTUAL"
                  className="form-control"
                  placeholder="PRECIO_ACTUAL"
                  onChange={this.UpdateState}
                  readOnly={!this.props.modificar}
                  defaultValue={this.state.PRECIO_ACTUAL}
                />
              </div>
              <div className="form-group">
                <label>FECHA_INGRESO:</label>
                <input
                  type="date"
                  name="FECHA_INGRESO"
                  className="form-control"
                  placeholder="FECHA_INGRESO"
                  onChange={this.UpdateState}
                  readOnly={!this.props.modificar}
                  defaultValue={this.state.FECHA_INGRESO}
                />
              </div>

              <div className="form-group">
                <label>Usuario:</label>
                <input
                  type="text"
                  name="USUARIO_USUARIO"
                  className="form-control"
                  placeholder="Usuario"
                  onChange={this.UpdateState}
                  readOnly={!this.props.modificar}
                  defaultValue={this.state.USUARIO_USUARIO}
                />
              </div>

              <div className="form-group">
                <label>Nombre del Usuario:</label>
                <input
                  type="text"
                  name="NOMBRE_USUARIO"
                  className="form-control"
                  placeholder="Nombre del usuario"
                  onChange={this.UpdateState}
                  readOnly={!this.props.modificar}
                  defaultValue={this.state.NOMBRE_USUARIO}
                />
              </div>

                {/* <div className="form-group">
                  <label>Direccion Usuario:</label>
                  <input
                    type="text"
                    name="DIRECCION_USUARIO"
                    className="form-control"
                    placeholder="Direccion del usuario"
                    onChange={this.UpdateState}
                    readOnly={!this.props.modificar}
                    defaultValue={this.state.DIRECCION_USUARIO}
                  />
                </div>

                <div className="form-group">
                  <label> DPI Usuario:</label>
                  <input
                    type="text"
                    name="DPI_USUARIO"
                    className="form-control"
                    placeholder="DPI del usuario"
                    onChange={this.UpdateState}
                    readOnly={!this.props.modificar}
                    defaultValue={this.state.DPI_USUARIO}
                  />
                </div>

                <div className="form-group">
                  <label>Telefono:</label>
                  <input
                    type="text"
                    name="TELEFONO_USUARIO"
                    className="form-control"
                    placeholder="Telefono del usuario"
                    onChange={this.UpdateState}
                    readOnly={!this.props.modificar}
                    defaultValue={this.state.TELEFONO_USUARIO}
                  />
                </div>

                <div className="form-group">
                  <label>Estado:</label>
                  <input
                    type="text"
                    name="ESTADO"
                    className="form-control"
                    placeholder="Estadp del usuario"
                    onChange={this.UpdateState}
                    readOnly={!this.props.modificar}
                    defaultValue={this.state.ESTADO}
                  />
                </div> */}
              {this.props.modificar &&(
              <button
                disabled={this.validarForm()}
                type="submit"
                className="btn btn-success float-right"
              >
                Editar Usuario
              </button>
              )}
            </form>
          </div>
        </Fragment>
      );
    }
  }
  
  export default withRouter(PrecioDiaEditar);
  