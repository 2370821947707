import React, { Component, Fragment } from "react";
  import { fetchPut,fetchGet } from "../../../utils/Fetch";
  import { withRouter, Redirect } from "react-router-dom";
  import Select from "react-select";
  import makeAnimated from "react-select/animated";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

  const initialState = {
    ID_UNIDAD:""
    ,NOMBRE_UNIDAD:""
    ,DESCRIPCION_UNIDAD:""
    ,PLACA_UNIDAD:""
    ,VIN_UNIDAD:""
    ,ID_TIPO_UNIDAD:""
    ,ULTIMO_ODOMETRO_UNIDAD:""
    ,FECHA_ULTIMO_ODOMETRO_UNIDAD:""
    ,BLOQUEADA_UNIDAD:""
    ,ID_EMPRESA:""
    ,ASIGNADA:""
  };
  
  class UnidadEditar extends Component {
    state = {
      ...initialState,
    };
  
    
  updateStateSelectTipo = (tipo) => {
    this.setState({tipo,
      ID_TIPO_UNIDAD:tipo.ID_TIPO_UNIDAD,
    });
  };


    clearState = () => {
      this.setState({
        ...initialState,
      });
    };
  
    UpdateState = (e) => {
      const { name, value } = e.target;
      this.setState({
        [name]: value,
      });
    };
  
    validarForm = () => {
      const {NOMBRE_UNIDAD
        ,DESCRIPCION_UNIDAD
        ,PLACA_UNIDAD
        ,VIN_UNIDAD
        ,ULTIMO_ODOMETRO_UNIDAD
        ,FECHA_ULTIMO_ODOMETRO_UNIDAD
        } = this.state;
      const noValido = !NOMBRE_UNIDAD|| ! DESCRIPCION_UNIDAD|| ! PLACA_UNIDAD|| !VIN_UNIDAD|| !ULTIMO_ODOMETRO_UNIDAD|| !FECHA_ULTIMO_ODOMETRO_UNIDAD;
      return noValido;
    };
    
  async componentDidMount() {
    const { id } = this.props.match.params;

    const data = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/unidad/${id}`
    );
    this.setState({ ...data.data[0] });

    const data1 = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/tipounidad`
    );

    const tiposunidad=data1.data;
    const tipo=tiposunidad.find(TIP=>TIP.ID_TIPO_UNIDAD===data.data[0].ID_TIPO_UNIDAD)

    this.setState({tiposunidad, tipo });




  }
  
    EditarUnidad = async (e) => {
      e.preventDefault();
  
      const data = await fetchPut(
        `${process.env.REACT_APP_SERVER}/api/unidad/${this.state.ID_UNIDAD}`,
        this.state
      );
      this.setState({ data: data.data });
      alert(data.message);
      this.props.history.push("/unidad");
    };
  
    render() {
      const redireccion = this.props.Access("ModificarUnidades") ? (
        ""
      ) : (
        <Redirect to="/login" />
      );

      const mensaje = this.props.modificar ? (
        "Editar Unidad"
      ) : (
        "Detalle Tipo Unidad"
      );
  
      return (
        <Fragment>
          {redireccion}
      <h1 className="text-center mb-5">{mensaje}</h1>

      <div className="row justify-content-center">
       <form
    className="col-md-8 col-sm-12"
    onSubmit={(e) => this.EditarUnidad(e)}
  >
    <div className="form-group">
      <label>Nombre Unidad:</label>
      <input
        type="text"
        name="NOMBRE_UNIDAD"
        className="form-control"
        placeholder="Nombre"
        onChange={this.UpdateState}
        defaultValue={this.state.NOMBRE_UNIDAD}
        readOnly={!this.props.modificar} 
      />
    </div>

    <div className="form-group">
      <label>Descripcion:</label>
      <input
        type="text"
        name="DESCRIPCION_UNIDAD"
        className="form-control"
        placeholder="Descripcion"
        onChange={this.UpdateState}
        defaultValue={this.state.DESCRIPCION_UNIDAD}
        readOnly={!this.props.modificar} 
      />
    </div>

    <div className="form-group">
      <label>Placa unidad:</label>
      <input
      type="text"
        name="PLACA_UNIDAD"
        className="form-control"
        placeholder="Placa"
        onChange={this.UpdateState}
        defaultValue={this.state.PLACA_UNIDAD}
        readOnly={!this.props.modificar} 
      />
    </div>
    <div className="form-group">
      <label>Vin unidad:</label>
      <input
      type="text"
      name="VIN_UNIDAD"
      className="form-control"
      placeholder="Vin Unidad"
        onChange={this.UpdateState}
        defaultValue={this.state.VIN_UNIDAD}
        readOnly={!this.props.modificar} 
      />
    </div>



    <div className="form-group">
      <label>Ultimo odometro Unidad:</label>
      <input
      type="text"
      name="ULTIMO_ODOMETRO_UNIDAD"
      className="form-control"
      placeholder="Ultimo odometro unidad"
        onChange={this.UpdateState}
        defaultValue={this.state.ULTIMO_ODOMETRO_UNIDAD}
        readOnly={!this.props.modificar} 
      />
    </div>

  
    <div className="form-group">
              <label>Fecha Ultimo Odometro:</label>
              </div>

            <div className="form-group">

              <input
                type="date"
                name="FECHA_ULTIMO_ODOMETRO_UNIDAD"
                className="form-control"
                placeholder="Descripcion Tarjeta"
                onChange={this.UpdateState}
                readOnly={!this.props.modificar}
                defaultValue={this.state.FECHA_ULTIMO_ODOMETRO_UNIDAD}
              />

              {/* <DatePicker 
               dateFormat="yyyy/MM/dd"
                disabled={!this.props.modificar}
                readOnly={!this.props.modificar}
               selected={this.state.FECHA_ULTIMO_ODOMETRO_UNIDAD} onChange={date => this.setState({FECHA_ULTIMO_ODOMETRO_UNIDAD:date})} /> */}
                          </div>


    <div className="form-group">
      <label>Bloqueada Unidad:</label>
      <input
      type="text"
      name="BLOQUEADA_UNIDAD"
      className="form-control"
      placeholder="Bloqueada Unidad"
      
        defaultValue={(this.state.BLOQUEADA_UNIDAD===true?"SI":"NO")}
        readOnly
      />
    </div>


    <div className="form-group">
    <label>Asignada:</label>
    <input
       type="text"
       name="ASIGNADA"
       className="form-control"
       placeholder="Asignada"
      
        defaultValue={(this.state.ASIGNADA===true?"SI":"NO")}
        readOnly
      />
    </div>

    <div className="form-group">
                <label>Tipo Unidad:</label>
            <Select
                onChange={this.updateStateSelectTipo}
                options={this.state.tiposunidad}
                isMulti={false}
                components={makeAnimated()}
                isDisabled={!this.props.modificar}
                placeholder={"Seleccione el tipo de unidad"}
                getOptionLabel={(options) => options.NOMBRE_TIPO_UNIDAD}
                getOptionValue={(options) => options.ID_TIPO_UNIDAD}
                value={this.state.tipo}
              />
              </div>
    
    {this.props.modificar && (            
              <button
                disabled={this.validarForm()}
                type="submit"
                className="btn btn-success float-right"
              >
      Editar Unidad
    </button>
    )}
  </form>
</div>
        </Fragment>
      );
    }
  }
  
  export default withRouter(UnidadEditar);
  