import React, { Component, Fragment } from "react";
import { fetchPost,fetchGet } from "../../../utils/Fetch";
import { withRouter, Redirect } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const initialState = {
  ID_TIPO_UNIDAD:""
  ,NOMBRE_TIPO_UNIDAD:""
  ,FECHA_ASIGNACION:new Date()
  ,ESTADO:true,
  ID_USUARIO:1,
  ID_EMPRESA:1
};

class TipoUnidadNuevo extends Component {
  state = {
    ...initialState,
  };

  clearState = () => {
    this.setState({
      ...initialState,
    });
  };

  UpdateState = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  validarForm = () => {
    const {ID_TARJETA, ID_UNIDAD, FECHA_ASIGNACION,ID_PILOTO} = this.state;
    const noValido = !ID_TARJETA || !ID_UNIDAD || !FECHA_ASIGNACION || !ID_PILOTO;
    return noValido;
  };

  async componentDidMount() {
    const data = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/tarjeta/asingacion/undefined`
    );
    const datauni = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/unidad/asingacion/${false}`
    );

    const datapiloto = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/piloto`
    );


    this.setState({ Tarjetas:data.data, Unidades:datauni.data,Pilotos:datapiloto.data});
  }
  updateStateSelectTarjeta = (Tarjeta) => {
    this.setState({Tarjeta,
      ID_TARJETA:Tarjeta.ID_TARJETA,
    });
  };
  updateStateSelectUnidad = (Unidad) => {
    this.setState({Unidad,
      ID_UNIDAD:Unidad.ID_UNIDAD
    });
  };
  updateStateSelectPiloto = (Piloto) => {
    this.setState({Piloto,
      ID_PILOTO:Piloto.ID_PILOTO
    });
  };

  CrearTipoUnidad = async (e) => {
    e.preventDefault();
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/asignaciontarjetaunidad`,
      this.state
    );
    this.setState({ data: data.data });
    alert(data.message);
    this.props.history.push("/asingtarjetaPivote");
  };
  render() {
    const redireccion = this.props.Access("CrearAsigancionTarjetaUnidadPivote") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );
    return (
      <Fragment>
        {redireccion}
        <h1 className="text-center mb-5">Asignacion de Tarjeta Comodin</h1>
        <div className="row justify-content-center">
          <form
            className="col-md-8 col-sm-12"
            onSubmit={(e) => this.CrearTipoUnidad(e)}
          >
            <div className="form-group">
              <label>Tarjeta:</label>
              <Select
                onChange={this.updateStateSelectTarjeta}
                options={this.state.Tarjetas}
                isMulti={false}
                components={makeAnimated()}
                placeholder={"Seleccione la tarjeta"}
                getOptionLabel={(options) => options.NUMERO_TARJETA}
                getOptionValue={(options) => options.ID_TARJETA}
                value={this.state.Tarjeta}
              />
            </div>
            <div className="form-group">
              <label>Unidad:</label>
              <Select
                onChange={this.updateStateSelectUnidad}
                options={this.state.Unidades}
                isMulti={false}
                components={makeAnimated()}
                placeholder={"Seleccione la Unidad"}
                getOptionLabel={(options) => options.NOMBRE_UNIDAD}
                getOptionValue={(options) => options.ID_UNIDAD}
                value={this.state.Unidad}
              />
            </div>
            <div className="form-group">
              <label>Piloto:</label>
              <Select
                onChange={this.updateStateSelectPiloto}
                options={this.state.Pilotos}
                isMulti={false}
                components={makeAnimated()}
                placeholder={"Seleccione el Piloto"}
                getOptionLabel={(options) => options.NOMBRE_PILOTO}
                getOptionValue={(options) => options.ID_PILOTO}
                value={this.state.Piloto}
              />
            </div>
            <div className="form-group">
              <label>FECHA_ASIGNACION:</label>
              <input
                type="date"
                name="FECHA_ASIGNACION"
                className="form-control"
                placeholder="FECHA_ASIGNACION"
                onChange={this.UpdateState}
                defaultValue={this.state.FECHA_ASIGNACION}
              />
            </div>
            <button
              disabled={this.validarForm()}
              type="submit"
              className="btn btn-success float-right"
            >
              Asignacion Tarjeta
            </button>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(TipoUnidadNuevo);