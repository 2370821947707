import React, { Component, Fragment } from "react";
import { fetchPost } from "../../../utils/Fetch";
import { withRouter, Redirect } from "react-router-dom";

const initialState = {
  ID_COMBUSTIBLE:""
  ,NOMBRE_COMBUSTIBLE:""
  ,PRECIO_COMBUSTIBLE:""
  ,DESCRIPCION:""
  ,ID_ESTACION:""
  ,ESTADO:true
  ,ID_USUARIO: 1
};

class CombustibleNuevo extends Component {
  state = {
    ...initialState,
  };

  clearState = () => {
    this.setState({
      ...initialState,
    });
  };

  UpdateState = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  validarForm = () => {
    const { NOMBRE_COMBUSTIBLE,PRECIO_COMBUSTIBLE,DESCRIPCION} = this.state;
      const noValido = ! NOMBRE_COMBUSTIBLE|| ! PRECIO_COMBUSTIBLE|| ! DESCRIPCION;
      return noValido;
  };

  CrearCombustible = async (e) => {
    e.preventDefault();

    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/combustible`,
      this.state
    );
    this.setState({ data: data.data });
    alert(data.message);
    this.props.history.push("/combustible");
  };

  render() {
    const redireccion = this.props.Access("CrearCombustible") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );

    return (
      <Fragment>
        {redireccion}
        <h1 className="text-center mb-5">Nuevo Combustible</h1>

        <div className="row justify-content-center">
          <form
            className="col-md-8 col-sm-12"
            onSubmit={(e) => this.CrearCombustible(e)}
          >
            <div className="form-group">
              <label>Nombre Combustible:</label>
              <input
                type="text"
                name="NOMBRE_COMBUSTIBLE"
                className="form-control"
                placeholder="Nombre Combustible"
                onChange={this.UpdateState}
                defaultValue={this.state.NOMBRE_COMBUSTIBLE}
              />
            </div>

            <div className="form-group">
              <label>Precio Combustible:</label>
              <input
                type="text"
                name="PRECIO_COMBUSTIBLE"
                className="form-control"
                placeholder="Descripcion Combustible"
                onChange={this.UpdateState}
                defaultValue={this.state.PRECIO_COMBUSTIBLE}
              />
            </div>

            <div className="form-group">
              <label>Descripcion:</label>
              <input
                type="text"
                name="DESCRIPCION"
                className="form-control"
                placeholder="Descripcion"
                onChange={this.UpdateState}
                defaultValue={this.state.DESCRIPCION}
              />
            </div>

            <button
              disabled={this.validarForm()}
              type="submit"
              className="btn btn-success float-right"
            >
              Nuevo Combustible
            </button>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(CombustibleNuevo);
