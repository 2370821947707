import React, { Component, Fragment } from "react";
import {  fetchPost,fetchGet } from "../../utils/Fetch";
import { Redirect } from "react-router-dom";
import { CSVLink} from "react-csv";
import Moment from "react-moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactExport from "react-export-excel";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { relativeTimeThreshold } from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const   headers = [
  { label: "No", key: "No" },
  { label: "FECHA", key: "FECHA" },
  { label: "NOMBRE_USUARIO", key: "NOMBRE_USUARIO" },
  { label: "USUARIO", key: "USUARIO_USUARIO" },
  { label: "NOMBRE_EMPRESA", key: "NOMBRE_EMPRESA" },
  { label: "MONEDA", key: "MONEDA" },
  { label: "TOTAL", key: "TOTAL" },
];


const estadoInicial = { BuscarDatos: "", data: null,estado:"Re Activar"};

class Reporte_Uno extends Component {
  constructor(props) {
    super(props);
    this.state = {pagar:false,ALLPAGADO:false, data: estadoInicial,headers:headers,dataFiltrada:[], CSV:[],FECHA_INICIO: new Date(),
      FECHA_FIN:new Date(),TOTAL:{TOTAL:0} };
  }
  updateStateSelectEstacion = (Empresa) => {
    this.setState({ Empresa, ID_EMPRESAR: Empresa.ID_EMPRESA });
  };
async componentDidMount() {

    const dataEmpresasn = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/empresa/ALL`
    );

    this.setState({
      Empresas:dataEmpresasn.data
    })
  //  this.Buscar();
  }

  Buscar = async () =>{
    const data = await fetchPost(`${process.env.REACT_APP_SERVER}/api/reportes/RP_CONSUMO_COMBUSTIBLE_PAGADO_LISTODOS`,this.state);
    if(data.data){
    await this.setState({ dataFiltrada: data.data, data: data.data});

    let TotalReal=0;

   
    await data.data.forEach((dat) =>{
      TotalReal+=dat.TOTAL;
    });
    
    
    this.setState({
      TOTAL:{TOTAL:TotalReal}
    })
    
    {/*'es-GT', { timeZone: 'UTC' } */}

    let CSV =this.state.dataFiltrada.map((rows,index)=>({No:index+1,FECHA: new  Date((rows.FECHA)).toLocaleString(),"NOMBRE_USUARIO":rows.NOMBRE_USUARIO,"USUARIO":rows.USUARIO_USUARIO,"NOMBRE_EMPRESA":(rows.NOMBRE_EMPRESA),"MONEDA":rows.MONEDA,"TOTAL":rows.TOTAL.toFixed(2)}));
    CSV=CSV.concat(
      {No:"TOTAL",FECHA: "","NOMBRE_USUARIO":"","USUARIO":"","NOMBRE_EMPRESA":(""),"MONEDA":"Q","TOTAL":(this.state.TOTAL.TOTAL).toFixed(2)}
    )
   
   
   
    this.setState({ CSV:CSV});
  }
    
  }



  UpdateState = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };



  exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "CONSUMO COMBUSTIBLE";
    const headers =[[ "No",
    "FECHA",
    "NOMBRE_USUARIO",
    "USUARIO",
    "NOMBRE_EMPRESA",
    "MONEDA",
    "TOTAL"  ]]
 {/*'es-GT', { timeZone: 'UTC' } */}
    let data = this.state.dataFiltrada.map((rows,index)=> [index+1,new  Date((rows.FECHA)).toLocaleString(),rows.NOMBRE_USUARIO,rows.USUARIO_USUARIO,rows.NOMBRE_EMPRESA,rows.MONEDA,rows.TOTAL.toFixed(2)]);

    data=data.concat(
     [ ["TOTAL","","","","","Q",(this.state.TOTAL.TOTAL).toFixed(2)]]
    );



    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    const fecha=new  Date().toLocaleString(); {/*'es-GT', { timeZone: 'UTC' } */}
    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(`CONSUMO_COMBUSTIBLE ${fecha}.pdf`);
  }

  // cambioEstado = async (e) => {
  //  const data = await fetchGet(`${process.env.REACT_APP_SERVER}/api/piloto`);
  //   this.setState({ dataFiltrada: data.data, data: data.data });
  //   const { name, value } = e.target;
  //   this.setState({
  //     [name]: value,
  //   });
  // };

  BuscarDatos = async(e) => {
    e.preventDefault();
    const patt = new RegExp(`${this.state.BuscarDatos}`, "gi");
     if(this.state.BuscarDatos && this.state.BuscarDatos.length>0)
{
  const datos = this.state.data.filter((dat) =>dat.NOMBRE_UNIDAD.toUpperCase()==this.state.BuscarDatos.toUpperCase());
  await this.setState({
    dataFiltrada: datos,
  });
}
else{
  const datos = this.state.data;
  await this.setState({
    dataFiltrada: datos,
  });
}

    let TotalReal=0
    await   this.state.dataFiltrada.forEach((dat) =>{
      TotalReal+=dat.TOTAL;
    });
    
    await   this.setState({
      TOTAL:{TOTAL:TotalReal}
    })

    let CSV =this.state.dataFiltrada.map((rows,index)=>({No:index+1,FECHA: new  Date((rows.FECHA)).toLocaleString(),"NOMBRE_USUARIO":rows.NOMBRE_USUARIO,"USUARIO":rows.USUARIO_USUARIO,"NOMBRE_EMPRESA":(rows.NOMBRE_EMPRESA),"MONEDA":rows.MONEDA,"TOTAL":rows.TOTAL.toFixed(2)}));
    CSV=CSV.concat(
      {No:"TOTAL",FECHA: "","NOMBRE_USUARIO":"","USUARIO":"","NOMBRE_EMPRESA":(""),"MONEDA":"Q","TOTAL":(this.state.TOTAL.TOTAL).toFixed(2)}
    )
   
    this.setState({ CSV:CSV});
    
  };

cambioEstado = (e) => {
  const { name, value } = e.target;
  this.setState({
    [name]: value,
  });
};


handleInputChange= (event) => {
  const target = event.target;
  console.log(target)
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const name = target.name;

  //console.log(target.checked,"target.checked")//es este

if(name=="ALLPAGADO"){
  
  const checkboxChange=this.state.data;//.find(x=>x.ID_DETALLE_CONSUMO_COMBUSTIBLE==name);
  checkboxChange.map(function(dato){
      dato.PAGADO = value;
  })
  //heckboxChange.PAGADO=value;

  this.setState({
    data:checkboxChange,
    ALLPAGADO:value
  })
  
}else{
  const checkboxChange=this.state.data;//.find(x=>x.ID_DETALLE_CONSUMO_COMBUSTIBLE==name);
  checkboxChange.map(function(dato){
    if(dato.ID_DETALLE_CONSUMO_COMBUSTIBLE == name){
      dato.PAGADO = value;
    }
  })
  //heckboxChange.PAGADO=value;
  
  
  this.setState({
    data:checkboxChange,
    ALLPAGADO:(value)?this.state.ALLPAGADO:value
  })
  
}


}


PAGAR = async (e) => {
  e.preventDefault();
  await this.setState({
    FECHA:new Date(),pagar:true
  })
  const data = await fetchPost(`${process.env.REACT_APP_SERVER}/api/reportes/PAGAR`,this.state);
  await this.Buscar();
  //this.setState({ data: data.data });
  alert(data.message);
  //this.props.history.push("/empresa");
//console.log(this.state.data);
//console.log(data);

await this.setState({
  FECHA:new Date(),pagar:false
})
};

  render() {
    const redireccion = this.props.Access("RP_CONSUMO_COMBUSTIBLE_PAGADO_LISTODOS") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );


    return (
      <Fragment>
        {redireccion}




        <h1 className="text-center mb-5">Reportes de Pago</h1>
        {/* <div className="form-group ml-5">
              <label>FECHA INICIO:</label>
              <input
                type="DATE"
                name="FECHA_INICIO"
                className="form-control"
                placeholder="Fecha Inicio"
                onChange={this.UpdateState}
                defaultValue={this.state.NOMBRE_EMPRESA}
              />
            </div> */}
            <div className="ml-5">
              <label>FECHA INICIO:</label>
              </div>

            <div className="ml-5">

              <DatePicker  showTimeSelect
                timeIntervals={5}
                timeFormat="HH:mm"
                dateFormat="yyyy/MM/dd HH:mm" selected={this.state.FECHA_INICIO} onChange={date => this.setState({FECHA_INICIO:date})} />
                          </div>

                          <div className="ml-5">
              <label>FECHA FIN:</label>
              </div>

            <div className="ml-5">

              <DatePicker  showTimeSelect
                timeIntervals={5}
                timeFormat="HH:mm"
                dateFormat="yyyy/MM/dd HH:mm" selected={this.state.FECHA_FIN} onChange={date => this.setState({FECHA_FIN:date})} />
                          </div>
                          <div className="ml-5">

               <label>Estacion:</label>
              <Select
                onChange={this.updateStateSelectEstacion}
                options={this.state.Empresas}
                isMulti={false}
                components={makeAnimated()}
                placeholder={"Seleccion la empresa"}
                getOptionLabel={(options) => options.NOMBRE_EMPRESA}
                getOptionValue={(options) => options.ID_EMPRESA}
                value={this.state.Empresa}
              /></div>
            <div className="form-group ml-5">
              {/* <label>FECHA FIN:</label>
              <input
                type="DATE"
                name="FECHA_FIN"
                className="form-control"
                placeholder="Fecha Final"
                onChange={this.UpdateState}
                defaultValue={this.state.NOMBRE_EMPRESA}
              /> */}
          <button onClick={this.Buscar}
          className="btn btn-link  float-right  ml-5 mr-5">
            BUSCAR POR FECHA
          </button>
            </div>
         

        {/* <form class="form-inline " onSubmit={this.BuscarDatos}>
          <label className="ml-5 mr-5">
            <strong>Nombre Unidad:</strong>
          </label>
          <input
            class="form-control mr-sm-5"
            type="search"
            name="BuscarDatos"
            onChange={this.cambioEstado}
            defaultValue={this.state.BuscarDatos}
            placeholder=""
            aria-label="Search"
          />
          <button class="btn btn-outline-dark my-2 my-sm-0" type="submit">
            Filtrar
          </button>
        </form> */}

      
    
{/* <CSVLink data={csvData}>Download me</CSVLink>;
// or
<CSVDownload data={csvData} target="_blank" />; */}

{/* exportar excel */}
<div className=" ml-5 mt-2  my-2"> {/*'es-GT', { timeZone: 'UTC' } */}
<CSVLink data={this.state.CSV} class="btn btn-secondary m-2 my-sm-0" headers={this.state.headers}  filename={`CONSUMO_COMBUSTIBLE ${new  Date().toLocaleString()}.csv`}>
 EXPORTAR CVS
</CSVLink>

{/* exportar pdf */}
 <button class="btn btn-danger m-2 my-sm-0" onClick={() => this.exportPDF()}>EXPORTAR PDF</button>



 <Fragment>
 {/*'es-GT', { timeZone: 'UTC' } */}
<ExcelFile  element={<button class="btn btn-success m-2 my-sm-0">EXPORTAR EXCEL</button>}
filename={`CONSUMO_COMBUSTIBLE ${new  Date().toLocaleString()}`} 

>
                <ExcelSheet data={this.state.CSV} name={`hoja1`}>
                <ExcelColumn  label="No" value= "No" />
                
 <ExcelColumn  label="No" value= "No" />          
 <ExcelColumn  label="FECHA" value= "FECHA" />
 <ExcelColumn  label="NOMBRE_USUARIO" value= "NOMBRE_USUARIO" />
 <ExcelColumn  label="USUARIO" value= "USUARIO_USUARIO" />
 <ExcelColumn  label="NOMBRE_EMPRESA" value= "NOMBRE_EMPRESA" />
 <ExcelColumn  label="MONEDA" value= "MONEDA" />
 <ExcelColumn  label="TOTAL" value= "TOTAL" />
{/*                 
                
                    <ExcelColumn label="Name" value="name"/>
                    <ExcelColumn label="Wallet Money" value="amount"/>
                    <ExcelColumn label="Gender" value="sex"/>
                    <ExcelColumn label="Marital Status"
                                 value={(col) => col.is_married ? "Married" : "Single"}/> */}
                </ExcelSheet>
            </ExcelFile>
</Fragment>

 </div>

{this.state.dataFiltrada && (
  <div className="responsibaTableReporte">
  <table>
    <tr>
<td>No.</td>
<td>FECHA</td>
<td>NOMBRE_USUARIO</td>
<td>USUARIO</td>
<td>NOMBRE_EMPRESA</td>
<td>MONEDA</td>
<td>TOTAL</td>
    </tr>
            {this.state.dataFiltrada.map((item,index) => {
              const { NOMBRE_UNIDAD } = item;
              return (
                <tr>
                <td>{index+1}</td>
                 <td>{new  Date((item.FECHA)).toLocaleString()}</td>
                  <td>{item.NOMBRE_USUARIO}</td>
                  <td>{item.USUARIO_USUARIO}</td>
                  <td>{item.NOMBRE_EMPRESA}</td>
                  <td>{item.MONEDA}</td>
                  <td>{item.TOTAL.toFixed(2)}</td>

    </tr>
              );
            })}

            
            <tr>
                  <td ><strong>TOTAL</strong></td>
                  <td ></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td><strong>Q</strong></td>
                  <td><strong>{this.state.TOTAL.TOTAL.toFixed(2)}</strong></td>

                  </tr>

</table>
  
          </div>
          
        )}
      </Fragment>
    );
  }
}

export default Reporte_Uno;
