import React, { Component, Fragment } from "react";
import { fetchGet } from "../../../utils/Fetch";
import { Link, Redirect } from "react-router-dom";

const estadoInicial = { BuscarDatos: "", data: null,};

class PrecioDia extends Component {
  constructor(props) {
    super(props);
    this.state = { data: estadoInicial };
  }

  Buscar = async () =>{
    const data = await fetchGet(`${process.env.REACT_APP_SERVER}/api/preciocombustibledia`);
    this.setState({ dataFiltrada: data.data, data: data.data, });
  }

   componentDidMount() {
   this.Buscar();
  }
  // cambioEstado = async (e) => {
  //  const data = await fetchGet(`${process.env.REACT_APP_SERVER}/api/piloto`);
  //   this.setState({ dataFiltrada: data.data, data: data.data });
  //   const { name, value } = e.target;
  //   this.setState({
  //     [name]: value,
  //   });
  // };

  BuscarDatos = (e) => {
    e.preventDefault();
    const patt = new RegExp(`${this.state.BuscarDatos}`, "gi");
    const datos = this.state.data.filter((dat) => patt.exec(dat.NOMBRE_COMBUSTIBLE));

    this.setState({
      dataFiltrada: datos,
    });
  };


cambioEstado = (e) => {
  const { name, value } = e.target;
  this.setState({
    [name]: value,
  });
};

  render() {
    const redireccion = this.props.Access("VerPrecioCombustibleDia") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );

    return (
      <Fragment>
        {redireccion}
        <h1 className="text-center mb-5">Precio Combustible Dia</h1>
        <form class="form-inline " onSubmit={this.BuscarDatos}>
          <label className="ml-5 mr-5">
            <strong>Nombre Combustible:</strong>
          </label>
          <input
            class="form-control mr-md-5"
            type="search"
            name="BuscarDatos"
            onChange={this.cambioEstado}
            defaultValue={this.state.BuscarDatos}
            placeholder=""
            aria-label="Search"
          />
          <button class="btn btn-outline-dark my-2 my-md-0" type="submit">
            Buscar
          </button>
        </form>
        {/* {this.props.Access("GuardarPrecioDia") && (
          <Link
            to={`${process.env.PUBLIC_URL}/PrecioDias/crear`}
            className="btn btn-link  ml-5 mr-5"
          >
            Crear
          </Link>
        )} */}

        {this.state.dataFiltrada && (
          <div className="ml-5 mr-5">
            <div className="row border">
              <div className="col-md-2 col-sm-3 col-xs-2">COMBUSTIBLE</div>
              <div className="col-md-2  d-none  d-sm-none d-md-block">ESTACION</div>
              <div className="col-md-2 col-sm-3  d-none  d-sm-block">FECHA_INGRESO</div>
              <div className="col-md-2 col-sm-3 col-xs-2">PRECIO_ACTUAL</div>
              <div className="col-md-2  d-none  d-sm-none d-md-block ">PRECIO_ANTERIOR</div>
              <div className="col-md-1  d-none d-sm-none d-md-block">USUARIO</div>
              <div className="col-md-1 col-sm-3 col-xs-1">Opciones</div>

            </div>
            {this.state.dataFiltrada.map((item) => {
              const { ID_PrecioDia } = item;
              return (
                <div className="row border" key={ID_PrecioDia}>
                  <div className="col-md-2 col-sm-3 col-xs-2">{item.NOMBRE_COMBUSTIBLE}</div>
                  <div className="col-md-2  d-none  d-sm-none d-md-block">{item.NOMBRE_ESTACON}</div>
                  <div className="col-md-2 col-sm-3  d-none  d-sm-block">{item.FECHA_INGRESO}</div>
                  <div className="col-md-2 col-sm-3 col-xs-2">{item.PRECIO_ACTUAL}</div>
                  <div className="col-md-2  d-none  d-sm-none d-md-block ">{item.PRECIO_ANTERIOR}</div>
                  <div className="col-md-1  d-none d-sm-none d-md-block">{item.NOMBRE_USUARIO}</div>
                  <div className="col-md-1 col-sm-3 col-xs-1">
                
                    {/* {this.props.Access("EditarPrecioDia") && (
                      <Link
                        to={`${process.env.PUBLIC_URL}/PrecioDias/modificar/${item.ID_PrecioDia}`}
                        className="btn btn-warning m-1"
                      >
                        Modificar
                      </Link>
                    )} */}

                    {this.props.Access("DetallePrecioCombustibleDia")  && (
                      <Link
                        to={`${process.env.PUBLIC_URL}/PrecioDia/detalle/${item.ID_PRECIO_COMBUSTIBLE_DIA}`}
                        className="btn btn-primary m-1"
                      >
                        Detalles
                      </Link>
                    )}
                   

                  </div>
                  {/* </td> */}
                  {/* </tr> */}
                </div>
              );
            })}
          </div>
        )}
      </Fragment>
    );
  }
}

export default PrecioDia;
