import React, { Component, Fragment } from "react";
import { fetchGet,fetchDelete} from "../../../utils/Fetch";
import { Link, Redirect } from "react-router-dom";

const estadoInicial = { BuscarDatos: "", data: null };

class Asignaciontarjetaunidad extends Component {
  constructor(props) {
    super(props);
    this.state = { data: estadoInicial };
  }

  Buscar = async () =>{
    const data = await fetchGet(`${process.env.REACT_APP_SERVER}/api/asignaciontarjetaunidad/PIVOTE`);
    this.setState({ dataFiltrada: data.data, data: data.data,estado:"Re Activar"});
  }

   componentDidMount() {
   this.Buscar();
  }
  // cambioEstado = async (e) => {
  //  const data = await fetchGet(`${process.env.REACT_APP_SERVER}/api/piloto`);
  //   this.setState({ dataFiltrada: data.data, data: data.data });
  //   const { name, value } = e.target;
  //   this.setState({
  //     [name]: value,
  //   });
  // };

  BuscarDatos = (e) => {
    e.preventDefault();
    const patt = new RegExp(`${this.state.BuscarDatos}`, "gi");
    const datos = this.state.data.filter((dat) => patt.exec(dat.NUMERO_TARJETA));

    this.setState({
      dataFiltrada: datos,
    });
  };

  BuscarDatosUnidad = (e) => {
    e.preventDefault();
    const patt = new RegExp(`${this.state.BuscarDatosUnidad}`, "gi");
    const datos = this.state.data.filter((dat) => patt.exec(dat.NOMBRE_UNIDAD));

    this.setState({
      dataFiltrada: datos,
    });
  };
cambioEstado = (e) => {
  const { name, value } = e.target;
  this.setState({
    [name]: value,
  });
};


Eliminar = async (ID_TIPO_UNIDAD) => {
  const data = await fetchDelete(
    `${process.env.REACT_APP_SERVER}/api/asignaciontarjetaunidad/${ID_TIPO_UNIDAD}/${false}`
  );
  alert(data.message);
  const dataGet = await fetchGet(
    `${process.env.REACT_APP_SERVER}/api/asignaciontarjetaunidad`
  );
  this.setState({ dataFiltrada: dataGet.data, data: dataGet.data });
};


ActivoReactivo =  (e) => {
  e.preventDefault();
  console.log(this.state.estado,"Re Activar")
if(this.state.estado==="Re Activar")
{
this.Inactivos();
}
else{
this.Buscar();
}
};


Reactivar = async (ID_TIPO_UNIDAD) => {
  const data = await fetchDelete(
    `${process.env.REACT_APP_SERVER}/api/asignaciontarjetaunidad/${ID_TIPO_UNIDAD}/${true}`
  );
  alert(data.message);
 this.Inactivos();
};

Inactivos = async () => {
const data = await fetchGet(`${process.env.REACT_APP_SERVER}/api/asignaciontarjetaunidad/inactivo`);
this.setState({ dataFiltrada: data.data, data: data.data,estado:"Activos"  });
};


  render() {
    const redireccion = this.props.Access("VerAsigancionTarjetaUnidadPivote") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );

    return (
      <Fragment>
        {redireccion}
        <h1 className="text-center mb-5">Asignacion de Tarjeta Comodin</h1>
        <form class="form-inline m-2" onSubmit={this.BuscarDatos}>
          <label className="ml-5 mr-5">
            <strong>Numero de tarjeta:</strong>
          </label>
          <input
            class="form-control mr-sm-5"
            type="search"
            name="BuscarDatos"
            onChange={this.cambioEstado}
            defaultValue={this.state.BuscarDatos}
            placeholder=""
            aria-label="Search"
          />
          <button class="btn btn-outline-dark my-2 my-sm-0" type="submit">
            Buscar
          </button>
        </form>
        <form class="form-inline m-2" onSubmit={this.BuscarDatosUnidad}>
          <label className="ml-5 mr-5">
            <strong>Nombre de Unidad:</strong>
          </label>
          <input
            class="form-control mr-sm-5"
            type="search"
            name="BuscarDatosUnidad"
            onChange={this.cambioEstado}
            defaultValue={this.state.BuscarDatosUnidad}
            placeholder=""
            aria-label="Search"
          />
          <button class="btn btn-outline-dark my-2 my-sm-0" type="submit">
            Buscar
          </button>
        </form>
        {this.props.Access("CrearAsigancionTarjetaUnidadPivote") && (
          <Link
            to={`${process.env.PUBLIC_URL}/asingtarjetaPivote/crear`}
            className="btn btn-link  ml-5 mr-5"
          >
            Crear
          </Link>
        )}

        {/* {this.props.Access("GuardarAccesos") && (
          <button 
          onClick={this.ActivoReactivo}
          className="btn btn-link  float-right  ml-5 mr-5">
           {
             this.state.estado
           } 

          </button>
        )} */}

        {this.state.dataFiltrada && (
          <div className="ml-5 mr-5">
            <div className="row border">
              <div className="col-md-2 col-xs-4">NUMERO_TARJETA</div>
              <div className="col-md-3  d-none d-sm-none d-md-block">NOMBRE_UNIDAD</div>
              <div className="col-md-2  d-none d-sm-none d-md-block">PLACA_UNIDAD</div>
              <div className="col-md-2  d-none d-sm-none d-md-block">FECHA_ASIGNACION</div>
              <div className="col-md-3 col-xs-4">Opciones</div>
            </div>
            {this.state.dataFiltrada.map((item) => {
              const { ID_ASIGNACION_TARJETA_UNIDAD } = item;
              return (
                <div className="row border" key={ID_ASIGNACION_TARJETA_UNIDAD}>
                   <div className="col-md-2 col-xs-4">{item.NUMERO_TARJETA}</div>
              <div className="col-md-3  d-none d-sm-none d-md-block">{item.NOMBRE_UNIDAD}</div>
              <div className="col-md-2  d-none d-sm-none d-md-block">{item.PLACA_UNIDAD}</div>
              <div className="col-md-2  d-none d-sm-none d-md-block">{item.FECHA_ASIGNACION}</div>
              <div className="col-md-3 col-xs-4">
                
                    {this.props.Access("ModificarAsigancionTarjetaUnidadPivote")  &&  (
                      <Link
                        to={`${process.env.PUBLIC_URL}/asingtarjetaPivote/modificar/${item.ID_ASIGNACION_TARJETA_UNIDAD}`}
                        className="btn btn-warning m-1"
                      >
                        Modificar
                      </Link>
                    )}

                    {this.props.Access("DetalleAsigancionTarjetaUnidadPivote")  &&  (
                      <Link
                        to={`${process.env.PUBLIC_URL}/asingtarjetaPivote/detalle/${item.ID_ASIGNACION_TARJETA_UNIDAD}`}
                        className="btn btn-primary m-1"
                      >
                        Detalles
                      </Link>
                    )}

                    {/* {this.props.Access("EliminarAccesos")  && item.ESTADO&& (
                      <button
                        onClick={() => {
                          if (window.confirm("Seguro que deseas eliminar tipo unidad")) {
                            this.Eliminar(item.ID_TIPO_UNIDAD);
                          }
                        }}
                        type="button"
                        className="btn btn-danger m-1 "
                      >
                        &times; Eliminar
                      </button>
                    )}
                  {this.props.Access("EliminarAccesos")  && !item.ESTADO && (
                      <button
                        onClick={() => {
                          if (window.confirm("Seguro que deseas eliminar tipo de estado")) {
                            this.Reactivar(item.ID_TIPO_UNIDAD);
                          }
                        }}
                        type="button"
                        className="btn btn-danger m-1 "
                      >
                        &times; Activar
                      </button>
                    )}
                 */}
                  </div>
                  {/* </td> */}
                  {/* </tr> */}
                </div>
              );
            })}
          </div>
        )}
      </Fragment>
    );
  }
}

export default Asignaciontarjetaunidad;
