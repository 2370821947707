import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { fetchGet } from "../../utils/Fetch";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


import carusel1 from "../../assets/Carousel/slider1.JPG";
import carusel2 from "../../assets/Carousel/slider2.JPG";
import carusel3 from "../../assets/Carousel/slider3.JPG";
import carusel4 from "../../assets/Carousel/slider4.JPG";
import carusel5 from "../../assets/Carousel/slider5.JPG";
import img6 from "../../assets/Carousel/slider6.JPG";
import img7 from "../../assets/Carousel/slider7.JPG";
import img8 from "../../assets/Carousel/slider8.JPG";
import img9 from "../../assets/Carousel/slider9.JPG";
import img10 from "../../assets/Carousel/slider10.JPG";
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup'



const carouselItems = [
  { nombre: 'slider1', img: carusel1 },
  { nombre: 'slider2', img: carusel2 },
  { nombre: 'slider3', img: carusel3 },
  { nombre: 'slider4', img: carusel4 },
  { nombre: 'slider5', img: carusel5 }
]
const cardItems = [
  { titulo: 'MISION',  img:img10,txt: 'SOMOS UNA EMPRESA DEDICADA AL SERVICIO Y OFRECER SOLUCIONES CONFIABLES DE CALIDAD A SU VEHICULO DE ACORDE A SUS NECESIDADES EN REFERENCIA A COMBUSTIBLES Y LUBRICANTES, BUSCANDO LA EXCELENCIA EN EL SERVICIO AL CLIENTE APLICANDO LAS TECNICAS Y TECNOLOGIAS INNOVADORAS A NUESTRO ALCANCE.' },
  { titulo: 'VISION',  img: img7,txt: 'SER LIDERES EN LA CALIDAD DE SERVICIO Y CONBUSTIBLES A NUESTROS CLIENTES BUSCANDO LA EXCELENCIA EN SER RECONOCIDOS A NIVEL NACIONAL SOCIALMENTE RESPONSABLE REALIZANDO NUESTRAS OPERACIONES EN UN MARCO SUSTENTABLE Y DE PROTECCION AL MEDIO AMBIENTE.'  },
  { titulo: 'VALORES', img: img8, txt: 'HONESTIDAD, RESPONSABILIDAD, ENTUSIASMO, TRABAJO EN EQUIPO, VERACIDAD, RESPETO, ACTITUD DE SERVICIO, ATENCION PERSONALIZADA, CALIDAD.' },
]
const precioslItems = [
  { nombre: 'slider1', txt: 'Prestando un servicio personalizado y de calidad a todos nuestros clientes.',img: img6 },
  { nombre: 'slider1', txt: 'Con infraestrura de primera calidad y servicio exacto', img: carusel3 },
  { nombre: 'slider1', txt: 'Con amplias instalaciones, mejorando nuetro servicio dia a dia.',img: carusel1 }

]

// import { fetchPost } from "../../utils/Fetch";
// import Error from '../Alertas/Error';
// import {
//   ResponsiveContainer,
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
// } from "recharts";

const initialState = {
  user: "",
  password: "",
  super:[],
diesel:[],
regular:[],
datadia:null,

};

class Inicio extends Component {
  state = {
    ...initialState,
  };

  actualizarState = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  limpiarState = () => {
    this.setState({ ...initialState });
  };

  async componentDidMount() {
    const data = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/preciocombustibledia/reporte`
    );

    if(data)
{
  this.setState({
      super: data.data.filter((fil) => fil.NOMBRE_COMBUSTIBLE === "SUPER"),
      diesel: data.data.filter((fil) => fil.NOMBRE_COMBUSTIBLE === "DIESEL"),
      regular: data.data.filter((fil) => fil.NOMBRE_COMBUSTIBLE === "REGULAR"),
    });}

    const datadia = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/preciocombustibledia/reportedia`
    );

    if(datadia){
    this.setState({
      datadia,
    });}
  }

  validarForm = () => {
    const { user, password } = this.state;

    const noValido = !user || !password;

    return noValido;
  };

  render() {
    // const data = [
    //   {
    //     "name": "Page A",
    //     "uv": 4000,
    //     "pv": 2400,
    //     "amt": 2400
    //   },
    //   {
    //     "name": "Page B",
    //     "uv": 3000,
    //     "pv": 1398,
    //     "amt": 2210
    //   },
    //   {
    //     "name": "Page C",
    //     "uv": 2000,
    //     "pv": 9800,
    //     "amt": 2290
    //   },
    //   {
    //     "name": "Page D",
    //     "uv": 2780,
    //     "pv": 3908,
    //     "amt": 2000
    //   },
    //   {
    //     "name": "Page E",
    //     "uv": 1890,
    //     "pv": 4800,
    //     "amt": 2181
    //   },
    //   {
    //     "name": "Page F",
    //     "uv": 2390,
    //     "pv": 3800,
    //     "amt": 2500
    //   },
    //   {
    //     "name": "Page G",
    //     "uv": 3490,
    //     "pv": 4300,
    //     "amt": 2100
    //   }
    // ]

    // const redireccion = this.props.Access("VerAcceso") ? (
    //   ""
    // ) : (
    //   <Redirect to="/login" />
    // );

    return (
      <Fragment>
        <Carousel>
          {carouselItems.map(slider =>{
            return (
               <Carousel.Item>
            <img
              className="d-block w-100"
              src={slider.img}
              alt="no jala" />

          </Carousel.Item>
            )
          })}

        </Carousel>
        <h1 class="titulo"><span></span></h1>
        <div className="tarjetas">
        <CardGroup>
          {cardItems.map(tarjetas =>{
            return(
              <Card className="tarjetaGen">
                <Card.Img variant="top" src={tarjetas.img} />
                <Card.Body>
                  <Card.Title className="titulosP">{tarjetas.titulo}</Card.Title>
                  <Card.Text>
                    {tarjetas.txt}
                  </Card.Text>
                </Card.Body>

              </Card>
            )
          })}
            <Card className="tarjetaGen">
              <Card.Img variant="top" src={img9} />
              <Card.Body>
                <Card.Title className="titulosP"></Card.Title>
                <Card.Text>
                  {this.state.datadia &&
                    this.state.datadia.data.map((da) => (
                      <Fragment>
                        <h4>
                          <strong>{da.NOMBRE_COMBUSTIBLE}</strong>:{" "}
                          {da.PRECIO_COMBUSTIBLE.toFixed(2)}
                        </h4>
                      </Fragment>
                    ))}
                </Card.Text>
              </Card.Body>

            </Card>
        </CardGroup><br />
          <h1 class="titulo"><span>
            <section class="main">

              <h2 class="cp-text">
                <span>T</span>
                <span>E</span>
                <span>X</span>
                <span>A</span>
                <span>C</span>
                <span>O</span>
                <span></span>
              </h2>

            </section></span></h1>
          <CardGroup>
            {precioslItems.map(precios => {
              return (
                <Card className="tarjetaGen">
                  <Card.Img variant="top" src={precios.img} />
                  <Card.Body>
                    <Card.Title className="titulosP"></Card.Title>
                    <Card.Text>
                     {precios.txt}
                    </Card.Text>
                  </Card.Body>

                </Card>
              )
            })}
          </CardGroup>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(Inicio);
