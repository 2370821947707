import React, { Component, Fragment } from "react";
import { fetchPost } from "../../../utils/Fetch";
import { withRouter, Redirect } from "react-router-dom";


const initialState = {
  NUMERO_TARJETA:""
  ,AUTORIZACION:""
  ,ODOMETRO: null
};

class AutorizacionNuevo extends Component {
  state = {
    ...initialState,
  };



  clearState = () => {
    this.setState({
      ...initialState,
    });
  };

  UpdateState = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  validarForm = () => {
    const {NUMERO_TARJETA, AUTORIZACION, ODOMETRO} = this.state;
    const noValido = !NUMERO_TARJETA || !AUTORIZACION || !ODOMETRO;
    return noValido;
  };

  async componentDidMount() {
   
  }
  
  CrearAutorizacion = async (e) => {
    e.preventDefault();
    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/asignacioncombustible/autorizacion`,
      this.state
    );
    this.setState({ data: data.data });
   // alert(data.message);
    // this.props.history.push("/asingtarjeta");

    if(this.state.data[0])
    {
    this.props.history.push("/ConsumoCombustible/crear/"+this.state.data[0].ID_ASIGNACION_COMBUSTIBLE);
    }
    else{
    alert("Datos Incorrectos");
    }

  };
  render() {
    const redireccion = this.props.Access("VerAutorizacion") && (this.props.auth[0].ID_EMPRESA===1) ? (
      ""
    ) : (
      <Redirect to="/login" />
    );
    return (
      <Fragment>
        {redireccion}
        <h1 className="text-center mb-5">AUTORIZACION</h1>
        <div className="row justify-content-center">
          <form
            className="col-md-8 col-sm-12"
            onSubmit={(e) => this.CrearAutorizacion(e)}
          >
           
            <div className="form-group">
              <label>NUMERO TARJETA:</label>
              <input
                type="text"
                name="NUMERO_TARJETA"
                className="form-control"
                placeholder="NUMERO_TARJETA"
                onChange={this.UpdateState}
                defaultValue={this.state.NUMERO_TARJETA}
              />
            </div>

            <div className="form-group">
              <label>AUTORIZACION:</label>
              <input
                type="text"
                name="AUTORIZACION"
                className="form-control"
                placeholder="AUTORIZACION"
                onChange={this.UpdateState}
                defaultValue={this.state.AUTORIZACION}
              />
            </div>
            <div className="form-group">
              <label>ODOMETRO:</label>
              <input
                type="number"
                name="ODOMETRO"
                className="form-control"
                placeholder="ODOMETRO"
                onChange={this.UpdateState}
                defaultValue={this.state.ODOMETRO}
              />
            </div>
            <button
              disabled={this.validarForm()}
              type="submit"
              className="btn btn-success float-right"
            >
              AUTORIZACION
            </button>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(AutorizacionNuevo);