import React, { Component, Fragment } from "react";
import { fetchPost, fetchGet } from "../../../utils/Fetch";
import { withRouter, Redirect } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import DatePicker from "react-datepicker";

const initialState = {
  ID_ASIGNACION_COMBUSTIBLE: "",
  ID_TARJETA: "",
  ID_COMBUSTIBLE: "",
  GALONES_COMBUSTIBLE: 0,
  FECHA_ASIGNACION: new Date(),
  FECHA_VENCE: new Date(new Date().setHours(new Date().getHours()+24)),
  ID_USUARIO: 1,
  ID_EMPRESA: 1,
  ESTADO: true,
  AUTORIZACION: "",
  NOMBRE_UNIDAD:"",
  ID_UNIDAD: "",
  ACTIVA: true,
  VALIDARODOMETRO: false,
  ODOTOMETRO: "",
  Unidad:[]
};



class AsignacionCombustibleNuevo extends Component {
  state = {
    ...initialState,
  };

  clearState = () => {
    this.setState({
      ...initialState,
    });
  };

  UpdateState = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };


  validarForm = () => {
    const {
      ID_TARJETA,
      ID_COMBUSTIBLE,
      GALONES_COMBUSTIBLE,
      FECHA_ASIGNACION,
      ID_USUARIO,
      ID_EMPRESA,
      AUTORIZACION,
      FECHA_VENCE,
      ID_UNIDAD,
      ODOTOMETRO,
    } = this.state;



    const odotometro_real=(parseInt(ODOTOMETRO)>parseInt(this.state.Unidad.ULTIMO_ODOMETRO_UNIDAD));
    const noValido = !odotometro_real ||
      !ID_TARJETA ||
      !ID_COMBUSTIBLE ||
      !GALONES_COMBUSTIBLE ||
      !FECHA_ASIGNACION ||
      !ID_USUARIO ||
      !ID_EMPRESA ||
      !AUTORIZACION ||
      !FECHA_VENCE ||
      !ID_UNIDAD ||
      !ODOTOMETRO;
    return noValido;
  };

  CrearAsignacionCombustible = async (e) => {
    e.preventDefault();




const {ID_TARJETA, 
  ID_COMBUSTIBLE, GALONES_COMBUSTIBLE, 
   ID_USUARIO, ID_EMPRESA, ESTADO, 
  AUTORIZACION, ID_UNIDAD, ACTIVA, VALIDARODOMETRO, ODOTOMETRO}=await this.state;
  const FECHA_ASIGNACION= new Date();
  const FECHA_VENCE= new Date(new Date().setHours(new Date().getHours()+24));


  const envioDatos={
    ID_TARJETA, 
  ID_COMBUSTIBLE, GALONES_COMBUSTIBLE, 
   ID_USUARIO, ID_EMPRESA, ESTADO, 
  AUTORIZACION, ID_UNIDAD, ACTIVA, VALIDARODOMETRO, ODOTOMETRO,FECHA_ASIGNACION,FECHA_VENCE};

    const data = await fetchPost(
      `${process.env.REACT_APP_SERVER}/api/asignacioncombustible`,
      envioDatos
    );
    this.setState({ data: data.data });
    alert(data.message);
    this.props.history.push("/asignacioncombustible");
  };

  async componentDidMount() {
    const dataTarjeta = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/tarjeta/asingacion/${true}`
    );

    const dataAsignacionTarjeta = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/asignaciontarjetaunidad`
    );


    const datacombustible = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/combustible`
    );
    const datauni = await fetchGet(
      `${process.env.REACT_APP_SERVER}/api/unidad/asingacion/${true}`
    );
    this.setState({
      Tarjetas: dataTarjeta.data,
      Combustibles: datacombustible.data.filter(x=> x.ID_COMBUSTIBLE==1 || x.ID_COMBUSTIBLE==2  ||  x.ID_COMBUSTIBLE==3  ),
      Unidades: datauni.data,
      AsignacionTarjeta: dataAsignacionTarjeta.data
    });
  }
  updateStateSelectUnidad = (Unidad) => {

const NUMERO_TARJETA = this.state.AsignacionTarjeta.find(dat=>dat.ID_UNIDAD===Unidad.ID_UNIDAD)




this.setState({ 
  Unidad, ID_TARJETA: NUMERO_TARJETA.ID_TARJETA,ID_UNIDAD:Unidad.ID_UNIDAD, 
  AUTORIZACION:NUMERO_TARJETA.AUTORIZACION, 
  NOMBRE_UNIDAD:NUMERO_TARJETA.NOMBRE_UNIDAD,
  NUMERO_TARJETA:NUMERO_TARJETA.NUMERO_TARJETA,
  ODOTOMETRO:Unidad.ULTIMO_ODOMETRO_UNIDAD
  });
 




  };
  updateStateSelectCombustibles = (Combustible) => {
    this.setState({ Combustible, ID_COMBUSTIBLE: Combustible.ID_COMBUSTIBLE });
  };

  // updateStateSelectUnidad = (Unidad) => {
  //   this.setState({ Unidad, ID_UNIDAD: Unidad.ID_UNIDAD });
  // };

  cambioFecha=(FECHA_ASIGNACION)=>{
    let FECHA_VENCE= new Date(FECHA_ASIGNACION)
    FECHA_VENCE= FECHA_VENCE.setHours(FECHA_VENCE.getHours()+24);



   this.setState({ FECHA_ASIGNACION,FECHA_VENCE:new Date(FECHA_VENCE)})
  // //  console.log(FECHA_VENCE.toUTCString())
   
  // //  console.log(FECHA_VENCE.toGMTString())
 
  // // var d = new Date(FECHA_ASIGNACION); 
  // var n = FECHA_VENCE.toUTCString(); 
  // console.log(n)
  }

  render() {
    const redireccion = this.props.Access("CrearAsignacionCombustible") ? (
      ""
    ) : (
      <Redirect to="/login" />
    );

    return (
      <Fragment>
        {redireccion}
        <h1 className="text-center mb-5">Nuevo Asignacion Combustible</h1>

        <div className="row justify-content-center">
          <form
            className="col-md-8 col-sm-12"
            onSubmit={(e) => this.CrearAsignacionCombustible(e)}
          >
            <div className="form-group">
              <label>Unidad:</label>
              <Select
                onChange={this.updateStateSelectUnidad}
                options={this.state.Unidades}
                isMulti={false}
                components={makeAnimated()}
                placeholder={"Seleccione la Unidad"}
                getOptionLabel={(options) => options.NOMBRE_UNIDAD}
                getOptionValue={(options) => options.ID_UNIDAD}
                value={this.state.Unidad}
              />
            </div>

            <div className="form-group">
              <label>Tarjeta:</label>
              {/* <Select
                onChange={this.updateStateSelectUnidad}
                options={this.state.Unidades}
                isMulti={false}
                components={makeAnimated()}
                placeholder={"Seleccione al Unidad"}
                getOptionLabel={(options) => options.NOMBRE_UNIDAD}
                getOptionValue={(options) => options.ID_UNIDAD}
                value={this.state.Unidad}
              /> */}
              <input
                type="text"
                name="NUMERO_TARJETA"
                className="form-control"
                placeholder="NUMERO_TARJETA"
                onChange={this.UpdateState}
                readOnly
                defaultValue={this.state.NUMERO_TARJETA}
              />
            </div>
            <div className="form-group">
              <label>Combustible:</label>
              <Select
                onChange={this.updateStateSelectCombustibles}
                options={this.state.Combustibles}
                isMulti={false}
                components={makeAnimated()}
                placeholder={"Seleccione el Combustible"}
                getOptionLabel={(options) => options.NOMBRE_COMBUSTIBLE}
                getOptionValue={(options) => options.ID_COMBUSTIBLE}
                value={this.state.Combustible}
              />
            </div>

            <div className="form-group">
              <label>Galones Combustible</label>
              <input
                type="number"
                name="GALONES_COMBUSTIBLE"
                className="form-control"
                placeholder="Numero de Galones"
                onChange={this.UpdateState}
                defaultValue={this.state.GALONES_COMBUSTIBLE}
              />
            </div>
            <div className="form-group">
              <label>Validar Odometro</label>
              <input
                type="checkbox"
                checked={this.state.VALIDARODOMETRO}
                name="VALIDARODOMETRO"
                className="form-control checkbox"
                placeholder="Numero de Galones"
                onChange={() => this.setState({VALIDARODOMETRO:!this.state.VALIDARODOMETRO})}
                defaultValue={this.state.VALIDARODOMETRO}
              />
            </div>
            <div className="form-group">
              <label>Odometro</label>
              <input
                type="number"
                name="ODOTOMETRO"
                className="form-control"
                placeholder="ODOTOMETRO"
                onChange={this.UpdateState}
                defaultValue={this.state.ODOTOMETRO}
              />
            </div>
            <div className="form-group">
              <label>Activa</label>
              <input
                type="text"
                name="ACTIVA"
                className="form-control"
                placeholder="Activa"
                readOnly
                onChange={this.UpdateState}
                defaultValue={(this.state.ACTIVA===true) ?'SI':'NO'}
              />
            </div>

            <div className="form-group">
              <label>Fecha Asignacion</label>
            </div>
            <div className="form-group">
              <DatePicker
                selected={this.state.FECHA_ASIGNACION}
                onChange={this.cambioFecha}
                readOnly
                showTimeSelect
                timeIntervals={5}
                timeFormat="HH:mm"
                dateFormat="yyyy/MM/dd HH:mm"
              />
            </div>
            <div className="form-group">
              <label>Fecha Vencimiento</label>
            </div>
            <div className="form-group">
              <DatePicker
                selected={this.state.FECHA_VENCE}
                onChange={(FECHA_VENCE) => this.setState({ FECHA_VENCE })}
                readOnly
                showTimeSelect
                timeIntervals={5}
                timeFormat="HH:mm"
                dateFormat="yyyy/MM/dd HH:mm"
              />
            </div>
            <div className="form-group">
              <label>Autorizacion</label>
              <input
                type="text"
                name="AUTORIZACION"
                className="form-control"
                placeholder="AUTORIZACION"
                onChange={this.UpdateState}
                readOnly
                defaultValue={this.state.AUTORIZACION}
              />
            </div>

            <button
              disabled={this.validarForm()}
              type="submit"
              className="btn btn-success float-right"
            >
              Nueva Asignacion Combustible
            </button>
          </form>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(AsignacionCombustibleNuevo);
